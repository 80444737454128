import React, { useState, useEffect } from 'react';
// MUI Components
import {
    Fade,
    Box,
    useTheme,
    CircularProgress,
    Typography
} from '@mui/material';

// Custom Components
import Landing from '../components/Landing/Landing';
import Login from '../components/Login/Login';
import SignUp from '../components/Login/SignUp';
import Admin from './Admin/Admin';
import axios from 'axios';
import consts from '../consts/consts';
import VerifyPhone from './Login/VerifyPhone';

// Navigation components
import BottomNavigationBar from './Navigation/BottomNavigation/BottomNavigation';
import AppTopBar from './Navigation/AppTopBar/AppTopBar';

// View components
import Today from './AppViews/Today/Today';
import Calendar from './AppViews/Calendar/Calendar';
import Credits from './AppViews/Credits/Credits';
import Profile from './AppViews/Profile/Profile';
import RegisterToClass from './AppViews/RegisterToClass/RegisterToClass';

// Helpers
import { checkExpiringCredits } from '../helpers/functions';

export default function Main(props) {
    let [logguedInUser, setLogguedInUser] = useState(null);
    let [admin, setAdmin] = useState(false);
    let [view, setView] = useState('landing');
    let [value, setValue] = useState(0);
    let [phoneToVerify, setPhoneToVerify] = useState('');
    let [coaches, setCoaches] = useState([]);
    let [mobile, setMobile] = useState(false);
    let [userType, setUserType] = useState('');
    let [registerToClass, setRegisterToClass] = useState(false);

    // Theme
    const theme = useTheme();

    // Initial use effect
    useEffect(() => {
        checkIfUserIsLoggedIn();
        loadLocalCoaches();
        (checkIfIOS() && isInStandaloneMode()) ? setMobile(true) : setMobile(false);

        // Edit iOS status bar color based on dark mode
        updateIOSStatusBar();
    }, []);

    // Hear for changes in view to handle bottom navigation bar
    useEffect(() => {
        (view === 'today') && setValue(0);
        (view === 'calendar') && setValue(1);
        (view === 'credits') && setValue(3);
        (view === 'profile') && setValue(4);
    }, [view]);

    // Hear for changes in bottom navigation bar to handle view changes
    useEffect(() => {
        (value === 0) && setView('today');
        (value === 1) && setView('calendar');
        (value === 3) && setView('credits');
        (value === 4) && setView('profile');
    }, [value]);

    // Hear for changes in user type to handle view changes
    useEffect(() => {
        switch (userType) {
            case 'admin':
                setAdmin(true);
                setView('dashboard');
                break;
            case 'user':
                setAdmin(false);
                // Only for Checkout modal - reload window and set view to my wallet
                if (localStorage.getItem('ssmx-purchaseCompleted')) {
                    localStorage.removeItem('ssmx-purchaseCompleted');
                    setView('credits');
                } else if (localStorage.getItem('ssmx-registerCompleted')) {
                    localStorage.removeItem('ssmx-registerCompleted');
                    setView('calendar');
                } else {
                    setView('today');
                }
                break;
            case '':
                setAdmin(false);
                setView('landing');
                break;
            default:
                break;
        }
    }, [userType]);

    // Update iOS status bar color
    const updateIOSStatusBar = () => {
        const meta = document.createElement('meta');
        meta.name = 'theme-color';
        meta.content = theme.palette.mode == 'dark' ? theme.palette.grey[800] : theme.palette.grey[100];
        document.head.appendChild(meta);
    }

    // Load local coaches
    let loadLocalCoaches = async () => {
        await axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/coach/getAll`)
            .then((res) => {
                setCoaches(res.data.result);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    // Check if user is loggued in
    let checkIfUserIsLoggedIn = async () => {
        // Obtain logguedin user from local storage
        let loggedInUser = await localStorage.getItem('ssmx-LoggedInUser');
        // Evaluate if user is loggued in
        if ((loggedInUser != '') && (loggedInUser != null)) {
            setView('');
            // There is logged in user, pull most recent data and set it to state
            // Check for expiring credits
            //await checkExpiringCredits(loggedInUser);
            await refreshUserData(loggedInUser);            
            // Evaluate type of user
            let userType = JSON.parse(loggedInUser).type;
            setUserType(userType);
            (userType === 'admin') ? setView('admin') : setView('today');
        } else {
            // There is no logged in user
            setAdmin(false);
            mobile ? setView('login') : setView('landing');
        }
    }

    // Check if we're in iOS and on Safari
    const checkIfIOS = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test( userAgent );
    }

    // Check if devide is in standalone mode
    const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

    // Obtain most recent user data from DB
    const refreshUserData = async (loggedInUser) => {
        await axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/user/findByEmail`, { "email" : JSON.parse(loggedInUser).email }) 
            .then((res) => {
                let retrievedUser = res.data.result[0];
                setUserType(retrievedUser.type);
                let encodedUser = JSON.stringify(retrievedUser);
                localStorage.setItem('ssmx-LoggedInUser', encodedUser);
                setLogguedInUser(retrievedUser);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        <>
            {/* App Top bar */}
            {
                (view !== 'login' && view !== 'signup' && view !== 'landing' && view !== '') &&
                <AppTopBar 
                    view={view}
                    setView={setView}
                    setLogguedInUser={setLogguedInUser} 
                    logguedInUser={logguedInUser}
                    admin={admin}
                />
            }
            {/* Register to Class */}
            <RegisterToClass
                registerToClass={registerToClass}
                setRegisterToClass={setRegisterToClass}
                logguedInUser={logguedInUser}
                setLogguedInUser={setLogguedInUser}
                refreshUserData={refreshUserData}
                coaches={coaches}
            />
            {/* Views */}
            {
                (view === 'landing') ?
                <Landing 
                    logguedInUser={logguedInUser} 
                    setLogguedInUser={setLogguedInUser}
                    setView={setView}
                    view={view}
                    admin={admin}
                    coaches={coaches}
                />
                : (view === 'login') ? 
                <Login 
                    logguedInUser={logguedInUser} 
                    setLogguedInUser={setLogguedInUser}
                    setView={setView}
                    view={view}
                    setAdmin={setAdmin}
                    setUserType={setUserType}
                />
                : (view === 'signup') ? 
                <SignUp 
                    logguedInUser={logguedInUser} 
                    setLogguedInUser={setLogguedInUser}
                    setView={setView}
                    view={view}
                    setAdmin={setAdmin}
                    setPhoneToVerify={setPhoneToVerify}
                    phoneToVerify={phoneToVerify}
                    setUserType={setUserType}
                />
                : (view === 'verifyPhone') ?
                <VerifyPhone
                    logguedInUser={logguedInUser}
                    setView={setView}
                    view={view}
                    setLogguedInUser={setLogguedInUser}
                    phoneToVerify={phoneToVerify}
                    setPhoneToVerify={setPhoneToVerify}
                />
                : ((view === 'dashboard' || consts.adminCategories.some(category => category.id === view)) && admin) ?
                <Fade in={(view === 'dashboard' || consts.adminCategories.some(category => category.id === view))}>
                    <Box>
                        <Admin 
                            logguedInUser={logguedInUser} 
                            setAdminView={setView} 
                            adminView={view} 
                            coaches={coaches}
                        />
                    </Box>
                </Fade>
                : (view === 'today') ?
                <Fade in={(view === 'today')}>
                    <Box sx={{mb: 5, mt: '0px'}}>
                        <Today
                            logguedInUser={logguedInUser}
                            setView={setView}
                            setLogguedInUser={setLogguedInUser}
                            darkMode={props.darkMode}
                            setPhoneToVerify={setPhoneToVerify}
                            coaches={coaches}
                            refreshUserData={refreshUserData}
                            setRegisterToClass={setRegisterToClass}
                            registerToClass={registerToClass}
                        />
                    </Box>
                </Fade>
                : (view === 'calendar') ?
                <Fade in={(view === 'calendar')}>
                    <Box sx={{mb: 5, mt: '-30px'}}>
                        <Calendar
                            logguedInUser={logguedInUser}
                            setView={setView}
                            setLogguedInUser={setLogguedInUser}
                            darkMode={props.darkMode}
                            setPhoneToVerify={setPhoneToVerify}
                            coaches={coaches}
                            refreshUserData={refreshUserData}
                        />
                    </Box>
                </Fade>
                : (view === 'credits') ?
                <Fade in={(view === 'credits')}>
                    <Box sx={{mb: 5}}>
                        <Credits
                            logguedInUser={logguedInUser}
                            setView={setView}
                            setLogguedInUser={setLogguedInUser}
                            darkMode={props.darkMode}
                            loadStripe={props.loadStripe}
                        />
                    </Box>
                </Fade>
                : (view === 'profile') ?
                <Fade in={(view === 'profile')}>
                    <Box sx={{mb: 5}}>
                        <Profile
                            setLogguedInUser={setLogguedInUser}
                            logguedInUser={logguedInUser}
                            coaches={props.coaches}
                            setPhoneToVerify={setPhoneToVerify}
                            setView={setView}
                        />
                    </Box>
                </Fade>
                :
                /* Logging in indicator */
                <Fade in={(view === '')}>
                    <Box
                        sx={{
                            alignContent: 'center',
                            height: '100vh'
                        }}
                    >
                        <Typography variant='h4' align='center' sx={{m:3}}>Cargando...</Typography>
                        <CircularProgress size={120}/>
                    </Box>
                </Fade>
            }
            {/* Bottom navigation bar [ONLY FOR LOGGUED-IN USER AND NOT LANDING PAGE] */}
            {
                (logguedInUser && userType === 'user' && view !== 'landing' && view !== '') 
                && 
                <BottomNavigationBar 
                    value={value} 
                    setValue={setValue}
                    setRegisterToClass={setRegisterToClass}
                />
            }
        </>
    )
}