import React, { useEffect } from "react";
import { Grid, Typography, CircularProgress, Table, TableRow, TableCell, TableHead } from "@mui/material";
import axios from "axios";
import { convertISODateToDDMMYYYY } from "../../helpers/functions";

export default function Birthdays() {
    const [users, setUsers] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        getUsers();
    }, []);

    const getUsers = async () => {
        setLoading(true);
        await axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/user/getBirthdayUsers`)
        .then((response) => {
            setUsers(response.data);
            setLoading(false);
        })
        .catch((error) => {
            console.log(error);
        });
    }

    return (
        <Grid container direction='column' pt={1} spacing={1}>
            <Grid xs={2} item>
                <Typography
                    sx={{
                        fontSize: '40px',
                        fontWeight: 'bold',
                        textAlign: 'left',
                        fontFamily: 'LarkenRegular'
                    }}
                >
                    {`Cumpleañeros del mes 🎉`}
                </Typography>
            </Grid>
            <Grid xs={4} item>
                {
                    loading ? <CircularProgress/> 
                    :
                    users.length > 0 ?
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Nombre</TableCell>
                                <TableCell>Fecha de nacimiento</TableCell>
                            </TableRow>
                        </TableHead>
                        {users.map((user) => (
                            <TableRow>
                                <TableCell>{`${user.firstName} ${user.lastName}`}</TableCell>
                                <TableCell>{convertISODateToDDMMYYYY(user.birthday)}</TableCell>
                            </TableRow>
                        ))}
                    </Table>
                    :
                    <Typography
                        sx={{
                            fontSize: '20px',
                            fontWeight: 'bold',
                            textAlign: 'left',
                            fontFamily: 'LarkenRegular'
                        }}
                    >
                        {`No hay cumpleañeros este mes`}
                    </Typography>
                }
            </Grid>
        </Grid>

    )
}