import React, { useState, useEffect, useRef } from 'react';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import { Grid, Typography, Box, Tab, useTheme, Grow } from '@mui/material';
import axios from 'axios';
import consts from '../../../consts/consts';
import MonthClassCard from '../../MonthClassCard/MonthClassCard';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';

const Puller = styled('div')(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
    borderRadius: 3,
    position: 'absolute',
    left: 'calc(50% - 15px)',
    marginTop: '10px'
}));

export default function Calendar(props) {
    // Theme
    const theme = useTheme();

    // Array of the current year plus 2 future years
    const currentYear = new Date().getFullYear();
    const years = [currentYear, currentYear + 1, currentYear + 2];

    // Calculate the initial month and year
    const currentMonth = new Date().getMonth(); // 0 for January, 1 for February, etc.
    const initialTabIndex = currentMonth;

    const tabsRef = useRef(null);

    const [selectedTab, setSelectedTab] = useState(initialTabIndex);
    const [classes, setClasses] = useState([]);
    
    // Scroll to tab on first render
    useEffect(() => {
        // Scroll the selected tab into view when the component mounts
        scrollToTab();
        // Scroll to the top of the page
        window.scrollTo(0, 0);
    }, []);
    
    // Get the classes on the selected month and year when the selected tab changes
    useEffect(() => {
        handleTabChange();
    }   , [selectedTab]);

    // Scroll to tab
    const scrollToTab = () => {
        if (tabsRef.current) {
            const selectedTabElement = tabsRef.current.querySelector(`.Mui-selected`);
            if (selectedTabElement) {
                setTimeout(() => {
                    selectedTabElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
                }, 500);
            }
        }
    }

    // Get the month and year from the selected tab
    const handleTabChange = async () => {
        const selectedMonth = selectedTab >= 12 ? (selectedTab - 12 + 1) : (selectedTab + 1);
        const selectedYear = years[Math.floor(selectedTab / 12)];
        // Query the DB for classes on the selected month and year
        if (props.logguedInUser) {
            await axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/schedule/findbyParticipantMonthYear`, { "month" : selectedMonth, "year" : selectedYear, "participantID" : props.logguedInUser._id })
            .then((res) => {
                setClasses(res.data.result);
            })
            .catch((err) => {
                console.log(err);
            });
        }
    }

    // Handle change for months tabs
    const handleMonthChange = (event, newTab) => {
        setSelectedTab(newTab);
    };

    // Custom label component for tabs
    const CustomTabLabel = ({ month, year }) => (
        <div>
            <div style={{ fontSize: '30px' }}>{month}</div>
            <div style={{ fontSize: '0.75rem', color: 'gray' }}>{year}</div>
        </div>
    );

    // Flatten the months and years into a single array for easy mapping
    const tabs = years.flatMap((year) =>
        consts.shortMonths.map((month, index) => ({ month, year }))
    );

    return (
        <Grid container direction="column" sx={{ mt: 2 }}>
            <Grid item xs={2}>
                <Box
                    sx={{
                        flexGrow: 1,
                        maxWidth: { xs: '100vw' },
                    }}
                >
                    <Tabs
                        value={selectedTab}
                        onChange={handleMonthChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        allowScrollButtonsMobile
                        ref={tabsRef}
                        aria-label="visible arrows tabs"
                        sx={{
                            [`& .${tabsClasses.scrollButtons}`]: {
                                '&.Mui-disabled': { opacity: 0.3 },
                            },
                        }}
                    >
                        {tabs.map((tab, index) => (
                            <Tab
                                key={index}
                                label={<CustomTabLabel month={tab.month} year={tab.year} />}
                                sx={{ fontSize: '16px' }}
                            />
                        ))}
                    </Tabs>
                </Box>
            </Grid>
            <Grid item xs={10} sx={{textAlign: 'left'}}>
                <Typography sx={{m: 1, fontFamily: 'LarkenRegular', textAlign: 'left'}}>Mis clases del mes</Typography>
                {classes.length === 0 ? (
                    <Typography variant="h6" align="center" sx={{ mt: 4, pb: 30 }}>
                        No hay clases asignadas este mes
                    </Typography>
                ) : (
                    classes.map((classData, index) => (
                        <Grow in={true} style={{ transitionDelay: `${50 * index}ms` }}>
                            <Box>
                                <MonthClassCard 
                                    classData={classData}
                                    coaches={props.coaches}
                                    logguedInUser={props.logguedInUser}
                                    refreshUserData={props.refreshUserData}
                                    handleTabChange={handleTabChange}
                                    scrollToTab={scrollToTab}
                                />
                            </Box>
                        </Grow>
                    ))
                )}
            </Grid>
        </Grid>
    );
}