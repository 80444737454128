import React, { useState, useEffect } from 'react';
import { 
    Modal, 
    Backdrop, 
    Fade, 
    Box, 
    Grid, 
    IconButton, 
    Typography,
    Grow,
    useTheme
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import MonthClassCard from '../MonthClassCard/MonthClassCard';

const UserClassesModal = (props) => {
    const [classes, setClasses] = useState([]);
    const theme = useTheme();

    // Use effect obtain all available users
    useEffect(() => {
        if (props.userID != null || props.userID != undefined || props.userID != '') {
            getClassesFromUser();
        }
    }, [props.userID]);

    // Fetch available users from API function
    const getClassesFromUser = async () => {
        await axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/schedule/findbyParticipantID`, { participantID: props.userID })
        .then(response => {
            setClasses(response.data.result);
        })
        .catch(error => console.error(error));
    }

    // Handle modal close
    const handleClose = () => {
        props.setUserID(null);
    };

    return (
        <Modal
            open={props.userID != null}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
        >
            <Fade in={props.userID != null}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 2,
                    width: {xs: '100vw', sm: '90vw', md: '80vw'},
                    height: {xs: '100vh', sm: '90vh', md: '80vh'},
                    overflowY: 'auto'
                }}>
                    <Grid 
                        container 
                        spacing={0} 
                        direction='column'
                        display={'flex'}
                        height={'100%'}
                    >
                        {/* Title and close button */}
                        <Grid item xs={1}>
                            <Grid container direction={'row'}>
                                <Grid item xs>
                                    <Typography variant="h6">Clases del cliente</Typography>
                                </Grid>
                                <Grid item>
                                    <IconButton variant="contained" onClick={handleClose}>
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* Participants */}
                        <Grid item xs={11} sx={{overflow: 'scroll'}}>
                            {classes.length === 0 ? (
                                <Typography variant="h6" align="center" sx={{ mt: 4, pb: 30 }}>
                                    El usuario no tiene clases asignadas
                                </Typography>
                            ) : (
                                classes.map((classData, index) => (
                                    <Grow in={true} style={{ transitionDelay: `${50 * index}ms` }}>
                                        <Box sx={{m: 0.75}}>
                                            <MonthClassCard 
                                                classData={classData}
                                                coaches={props.coaches}
                                                logguedInUser={props.logguedInUser}
                                            />
                                        </Box>
                                    </Grow>
                                ))
                            )}
                        </Grid>
                    </Grid>
                    
                </Box>
            </Fade>
        </Modal>
    );
};

export default UserClassesModal;