import consts from "../consts/consts";
import axios from "axios";
    
    // Function to compress the image and convert it to base64 format
    const compressImage = (file) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (event) => {
                const img = new Image();
                img.src = event.target.result;
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    const maxWidth = 350;
                    const maxHeight = 350;
                    let width = img.width;
                    let height = img.height;

                    if (width > height) {
                        if (width > maxWidth) {
                            height *= maxWidth / width;
                            width = maxWidth;
                        }
                    } else {
                        if (height > maxHeight) {
                            width *= maxHeight / height;
                            height = maxHeight;
                        }
                    }

                    canvas.width = width;
                    canvas.height = height;
                    ctx.drawImage(img, 0, 0, width, height);

                    const compressedBase64 = canvas.toDataURL('image/jpeg', 0.9);
                    // Do something with the compressed image in base64 format
                    resolve(compressedBase64);
                };
            };
        });
    }

    const otherFunction = () => {
        console.log('other function');
    }

    // Get AM or PM time
    const getFormattedTime = (time) => {
        time = convertISODateToLocalDate(new Date(time));
        let hours = time.getHours();
        let minutes = time.getMinutes();
        let ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        let strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    }

    // Convert ISO Date to local Date
    const convertISODateToLocalDate = (isoDateString) => {
        const date = new Date(isoDateString);
        const localDate = new Date(date.getTime() + date.getTimezoneOffset());
        return localDate;
    }

    // Convert ISO Date to DD/MM/YYYY
    const convertISODateToDDMMYYYY = (isoDateString) => {
        const localDate = convertISODateToLocalDate(isoDateString);
        const day = localDate.getDate();
        const month = localDate.getMonth() + 1;
        const year = localDate.getFullYear();
        return `${day}/${month}/${year}`;
    }

    // Convert Date to ISO Date
    const convertDateToISODate = (date) => {
        return new Date(date).toISOString();
    }

    // Get date day number
    const getDayNumber = (date) => {
        return new Date(date).getDate();
    }

    // Get day name
    const getDayName = (day) => {
        const dayNames = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
        return dayNames[day];
    };

    // Get month name
    const getMonth = (month) => {
        const monthNames = consts.shortMonths
        return monthNames[month];
    };

    // Get status
    const getStatus = (rawStatus) => {
        let status =    (rawStatus == 'scheduled') ? 'Activa' : 
                        (rawStatus == 'cancelled') ? 'Cancelada' :
                        (rawStatus == 'finalized') ? 'Finalizada' : 
                        '';
        return status;
    }

    // Calculate minutes duration of a class -- both variables are in ISO format
   const calculateDuration = (startTime, endTime) => {
    const start = new Date(startTime);
    const end = new Date(endTime);

    // Extract hours and minutes from start and end times
    const startHours = start.getHours();
    const startMinutes = start.getMinutes();
    const endHours = end.getHours();
    const endMinutes = end.getMinutes();

    // Calculate the difference in minutes
    const startTotalMinutes = startHours * 60 + startMinutes;
    const endTotalMinutes = endHours * 60 + endMinutes;
    const diff = Math.abs(endTotalMinutes - startTotalMinutes);

    return `${diff} min`;
    };

    // Check expiring credits
    const checkExpiringCredits = async (logguedInUser) => {
        if (logguedInUser === undefined || logguedInUser === null || logguedInUser === '') return;
        let obj = JSON.parse(logguedInUser);
        if (obj !== null && obj !== undefined && obj.packs !== null && obj.packs !== undefined) {
            let packs = obj.packs;
            let updateFlag = false;
            // obtain date from external API to avoid user manipulation
            const response = null;
            await axios.get('https://worldtimeapi.org/api/timezone/America/Mexico_City')
            .then(response => {
                console.log('Date obtained successfully from external API');
                response = response;
            })
            .catch(error => {
                console.error(error);
                return;
            });

            const date = (response !== null && response.data.datetime) ? response.data.datetime : new Date();
            const currentDate = new Date(date);
            const currentISODate = currentDate.toISOString();
            // Compare if expiration date is less than current date, if so set remainingClasses to 0 of that pack
            packs.forEach(pack => {
                let packExpirationDate = new Date(pack.expirationDate);
                let packISOExpirationDate = packExpirationDate.toISOString();
                if(packISOExpirationDate < currentISODate) {
                    updateFlag = true;
                    pack.remainingClasses = 0;
                }
            });
            // Update the user object with the new pack values to the DB
            if (updateFlag) {
                await axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/user/update`, { _id: obj._id, packs: packs })
                .then(response => {
                    console.log('Packs updated successfully in user object');
                })
                .catch(error => console.error(error));
            }
        }
    }

export { 
    compressImage, 
    otherFunction, 
    getFormattedTime, 
    convertISODateToLocalDate,
    getDayNumber,
    getDayName,
    getMonth,
    getStatus,
    calculateDuration,
    convertISODateToDDMMYYYY,
    convertDateToISODate,
    checkExpiringCredits
};