import React, { useState, useEffect } from 'react';
import { 
    Modal, 
    Backdrop, 
    Fade, 
    Box, 
    Grid, 
    IconButton, 
    Typography,
    Button,
    TextField,
    CircularProgress
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import MembershipCard from '../MembershipCard/MembershipCard';
import SwipeableViews from 'react-swipeable-views';
import CIcon from "@coreui/icons-react";
import * as icon from '@coreui/icons';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { convertISODateToLocalDate, convertDateToISODate } from '../../helpers/functions';

const MembershipModal = (props) => {
    const [userData, setUserData] = useState({});
    const [packToEdit, setPackToEdit] = useState(null);
    const [loading, setLoading] = useState(true);
    const [mode, setMode] = useState('view');
    // Use effect obtain all available users
    useEffect(() => {
        getUserDetails(props.membershipID);
    }, [props.membershipID]);

    // Get user details
    const getUserDetails = async (userID) => {
        setLoading(true);
        await axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/user/find`, { userID: userID })
        .then(response => {
            let retrievedData = response.data.result[0];
            // Order packs object from the most recent expirationDate to the oldest
            (retrievedData != undefined || retrievedData != null) && retrievedData.packs.sort((a, b) => new Date(b.expirationDate) - new Date(a.expirationDate));
            
            for (let i = 0; i < retrievedData.packs.length; i++) {
                // Check packs object, if any pack is missing id, assign it
                if (retrievedData.packs[i].id === undefined || retrievedData.packs[i].id === null) {
                    retrievedData.packs[i].id = `${userData._id}_${new Date().getTime()}`;
                }

                // Check packs object, if any pack is missing status, assign it
                if (retrievedData.packs[i].status === undefined || retrievedData.packs[i].status === null) {
                    retrievedData.packs[i].status = 'active';
                }
            }
            // Set user data
            setUserData(retrievedData);
            setLoading(false);
        })
        .catch(error => console.error(error));
    }

    // Handle modal close
    const handleClose = () => {
        props.setMembershipID(null);
    };

    // Handle selected pack to edit
    const setSelectedPack = (pack) => {
        setPackToEdit(pack);
        setMode('edit');
    }

    // Handle delete pack from user
    const handlePackDelete = (pack) => {
        // Update user packs array
        const prevMembershipArray = userData.packs;
        const updatedMembershipArray = prevMembershipArray.filter((membership) => membership.id !== pack.id);
        // Update local user object
        const updatedUser = {
            ...userData,
            packs: updatedMembershipArray
        }
        // Update user in database
        axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/user/update`, updatedUser)
        .then(response => {
            // Obtain updated user memberships
            getUserDetails(updatedUser._id);
        })
        .catch(error => console.error(error));
    }

    // Handle new pack creation
    const createNewPack = () => {
        setPackToEdit({});
        setMode('create');
    }

    // Handle input change to update packToEdit
    const handleChange = (fieldKey, newValue) => {
        if (fieldKey === 'date' || fieldKey === 'expirationDate') {
            newValue = convertDateToISODate(newValue);
        }

        setPackToEdit({
            ...packToEdit,
            [fieldKey]: newValue
        });
    }

    // Toggle pack status
    const togglePackStatus = (pack) => {
        let updatedPack = pack;
        updatedPack.status = (pack.status === 'active') ? 'paused' : 'active';

        // Update user with updated pack
        const updateUser = async () => {
            let updatedPacks = userData.packs.map((pack) => {
                if (pack.id === updatedPack.id) {
                    return updatedPack;
                } else {
                    return pack;
                }
            });

            let userDataToUpload = {
                _id: userData._id,
                packs: updatedPacks
            }

            await axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/user/update`, userDataToUpload)
            .then(response => {
                getUserDetails(userDataToUpload._id);
            })
            .catch(error => console.error(error));
        }

        updateUser();
    }

    // Update user with updated pack
    const updateUser = async () => {
        let userDataToUpload = {};

        // If new pack
        if (mode === 'create') {
            let packID = `${userData._id}_${new Date().getTime()}`;
            // Create pack object
            let packObj = {
                date: packToEdit.date,
                expirationDate: packToEdit.expirationDate,
                remainingClasses: packToEdit.remainingClasses,
                classes: packToEdit.classes,
                description: packToEdit.description,
                priceMXN: 0,
                status: 'active',
                id: packID
            }
            userDataToUpload = {
                _id: userData._id,
                packs: [...userData.packs, packObj]
            }
        // If edit pack
        } else {
            // Search pack ID under userData packs array and update it with packToEdit
            let updatedPacks = userData.packs.map((pack) => {
                if (pack.id === packToEdit.id) {
                    return packToEdit;
                } else {
                    return pack;
                }
            });

            userDataToUpload = {
                _id: userData._id,
                packs: updatedPacks
            }
        }
        

        await axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/user/update`, userDataToUpload)
        .then(response => {
            setPackToEdit(null);
            getUserDetails(userDataToUpload._id);
        })
        .catch(error => console.error(error));

    }

    return (
        <Modal
            open={props.membershipID != null}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
        >
            <Fade in={props.membershipID != null}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 2,
                    width: {xs: '100vw', sm: '90vw', md: '80vw'},
                    height: {xs: '100vh', sm: '90vh', md: '80vh'},
                    overflowY: 'auto'
                }}>
                    <Grid 
                        container 
                        spacing={0} 
                        direction='column'
                        display={'flex'}
                        height={'100%'}
                    >
                        {/* Title and close button */}
                        <Grid item xs={1}>
                            <Grid container direction={'row'}>
                                <Grid item xs>
                                    <Typography variant="h6">Membresías del cliente</Typography>
                                </Grid>
                                <Grid item>
                                    <IconButton variant="contained" onClick={handleClose}>
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* Participants */}
                        <Grid item xs={10} sx={{overflow: 'scroll'}}>
                            <SwipeableViews 
                                index={(packToEdit) ? 1 : 0}
                                containerStyle={{
                                    transition: 'transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s'
                                }}
                                onChangeIndex={(index) => {
                                    if (index === 0) {
                                        setPackToEdit(null);
                                        setMode('view');
                                    }
                                }} 
                                
                            >
                                <div>
                                    {/* Add membrership button */}
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => createNewPack()}
                                    sx={{ml: 1, mb: 1}}
                                >
                                    + Agregar membresía
                                </Button>
                                    {/* Memberships */}
                                    {
                                        loading &&
                                        <Grid container justifyContent={'center'}>
                                            <CircularProgress />
                                        </Grid>
                                    }
                                    {
                                        userData && userData.packs && userData.packs.length > 0 ?
                                        <Grid container direction='row' p={1} spacing={1}>
                                        {
                                            userData.packs.map((pack) => (
                                                <MembershipCard 
                                                    pack={pack} 
                                                    admin={true} 
                                                    setSelectedPack={setSelectedPack}
                                                    handlePackDelete={handlePackDelete}
                                                    togglePackStatus={togglePackStatus}
                                                />
                                            ))
                                        }
                                        </Grid>
                                        :
                                        <Typography>No hay membresías disponibles</Typography>
                                    }
                                </div>
                                    {/* Edit section */}
                                <div>
                                    <Grid alignSelf={'flex-start'} textAlign={'left'}>
                                        <Button 
                                            onClick={() => {
                                                setPackToEdit(null);
                                                setMode('view');
                                            }}>
                                            <CIcon 
                                                icon={icon.cilArrowLeft} 
                                                className='text-primary' 
                                                style={{height: '20px', marginRight: '20px'}}
                                            />
                                            <Typography sx={{textTransform: 'none'}}>Atrás</Typography>
                                        </Button>
                                    </Grid>
                                    <h3>{`${mode === 'edit' ? 'Editar' : 'Agregar'} membresia`}</h3>
                                    {
                                        packToEdit &&
                                        <Grid container spacing={1}>
                                            {/* Purchase date */}
                                                <Grid item xs={12} sm={6} key={'purchase-date-picker'}>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <DatePicker
                                                            label={'Fecha de compra'}
                                                            value={packToEdit['date'] ? convertISODateToLocalDate(packToEdit['date']) : null}
                                                            sx={{width: '100%'}}
                                                            timezone="America/Mexico_City"
                                                            onChange={(newValue) => handleChange('date', newValue)}
                                                            renderInput={(params) => <TextField {...params} />}
                                                            format="dd/MM/yyyy"
                                                            views={['year', 'month', 'day']}
                                                            openTo={'year'}
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                                {/* Expiration date */}
                                                <Grid item xs={12} sm={6} key={'expiration-date-picker'}>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <DatePicker
                                                            label={'Fecha de expiración'}
                                                            value={packToEdit['expirationDate'] ? convertISODateToLocalDate(packToEdit['expirationDate']) : null}
                                                            sx={{width: '100%'}}
                                                            timezone="America/Mexico_City"
                                                            onChange={(newValue) => handleChange('expirationDate', newValue)}
                                                            renderInput={(params) => <TextField {...params} />}
                                                            format="dd/MM/yyyy"
                                                            views={['year', 'month', 'day']}
                                                            openTo={'year'}
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                                {/* Remaining classes */}
                                                <Grid item xs={12} sm={6} key={'remaining-classes-tf-grid'}>
                                                    <TextField 
                                                        id={'remaining-classes-tf'}
                                                        label={'Clases restantes'}
                                                        variant="standard"
                                                        fullWidth
                                                        value={packToEdit['remainingClasses']}
                                                        onChange={(e) => handleChange('remainingClasses', Number(e.target.value))}
                                                        type={'number'}
                                                        inputProps={{ min: 0 }}
                                                    />
                                                </Grid>
                                                {
                                                    mode === 'create' &&
                                                    <>
                                                        {/* Classes in pack */}
                                                        <Grid item xs={12} sm={6} key={'pack-classes-tf-grid'}>
                                                            <TextField 
                                                                id={'pack-classes-tf'}
                                                                label={'Clases de la membresía'}
                                                                variant="standard"
                                                                fullWidth
                                                                value={packToEdit['classes']}
                                                                onChange={(e) => handleChange('classes', Number(e.target.value))}
                                                                type={'number'}
                                                                inputProps={{ min: 0 }}
                                                            />
                                                        </Grid>
                                                        {/* Classes in pack */}
                                                        <Grid item xs={12} sm={6} key={'description-tf-grid'}>
                                                            <TextField 
                                                                id={'description-tf'}
                                                                label={'Descripción'}
                                                                variant="standard"
                                                                fullWidth
                                                                value={packToEdit['description']}
                                                                onChange={(e) => handleChange('description', Number(e.target.value))}
                                                                type={'text'}
                                                            />
                                                        </Grid>
                                                    </>
                                                }
                                        </Grid>
                                    }
                                    <Grid container textAlign={'right'}>
                                        <Grid item xs>
                                            <Button 
                                                variant="contained" 
                                                color="primary"
                                                onClick={() => updateUser()}
                                            >
                                                Guardar
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </div>
                            </SwipeableViews>
                        </Grid>
                    </Grid>
                    
                </Box>
            </Fade>
        </Modal>
    );
};

export default MembershipModal;