import React from 'react';
import { Grid, Typography, Button, Divider } from '@mui/material';
// import AuraLogo from '../../../assets/aura-logo.png';

export default function ClientService (props) {

    const clickLink = (url) => window.open(url, "_blank");

    return (
        <>
            <h3>Servicio al cliente</h3>
            {/* <img src={AuraLogo} alt="Aura Logo" style={{ width: '100px', height: '100px' }} /> */}
            <h3>Si tienes dudas sobre el servicio puedes contactarnos via Instagram, ahí resolveremos todas tus dudas ☺️</h3>
            <Button
                onClick={async (e) => {
                    e.preventDefault();
                    await clickLink('https://ig.me/m/sculptstudiomx');
                }}
                variant="contained"
                color="primary"
                sx={{
                    textTransform: 'none',
                    color: '#FFF',
                    borderRadius: '20px',
                }}
            >
                Abrir Instagram
            </Button>
            <Divider sx={{m:3}}/>
            <h3>Aplicación desarrollada por:</h3>
            <Typography variant="h6">Diego Graciano</Typography>
            <Typography variant="h6">graciano.diego@hotmail.com</Typography>
            <Typography variant="h6">+52 33 3192 9721</Typography>
            <Button
                onClick={async (e) => {
                    e.preventDefault();
                    await clickLink('https://api.whatsapp.com/send/?phone=%2B523331929721&text&type=phone_number&app_absent=0');
                }}
                variant="contained"
                color="primary"
                sx={{
                    textTransform: 'none',
                    color: '#FFF',
                    borderRadius: '20px',
                    m:2
                }}
            >
                Contacto
            </Button>
        </>   
    )
}