import React, { useEffect, useState } from 'react';
import { Grid, Typography, Button } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

export default function PaymentCompletion(props) {
    const [isDarkMode, setIsDarkMode] = useState(false);
    const [user, setUser] = useState(null);
    const [pack, setPack] = useState(null);
    const [phone, setPhone] = useState(null);
    const [redirectStatus, setRedirectStatus] = useState(null);
    const location = useLocation();

    // Check for dark mode on load
    useEffect(() => {
        // Check if the action has already been performed
        const actionPerformed = sessionStorage.getItem('actionPerformed');

        if (actionPerformed === 'false') {
            // Get query params
            const queryParams = getQueryParams(location.search);
            const userParam = queryParams.get('user');
            const packParam = queryParams.get('pack');
            const phoneParam = queryParams.get('phone');
            const redirectStatusParam = queryParams.get('redirect_status');
            setUser(userParam);
            setPack(packParam);
            setPhone(phoneParam);
            setRedirectStatus(redirectStatusParam);
        }
        

    }, [location.search]);

    useEffect(() => {
        // Media query
        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        setIsDarkMode(mediaQuery.matches);

        const handleChange = (event) => {
            setIsDarkMode(event.matches);
        };

        mediaQuery.addEventListener('change', handleChange);
        return () => mediaQuery.removeEventListener('change', handleChange);
    }, []);

    useEffect(() => {
        if (user && pack && redirectStatus && phone) {
            
            // Find user in DB
            if (redirectStatus === 'succeeded') {
                handleUserPurchase(user, pack, phone);
            }
        }
    }, [user, pack, redirectStatus]);

    // Send expiration entry
    const sendExpirationEntry = async (pack, userID, phone) => {
        // Ge today
        const today = new Date();
        // Get notification date
        const notificationDate = await today.setDate(today.getDate() + (pack.validityDays - 5));
        // Get expiration date
        let expirationDate = new Date(today);
        expirationDate = await expirationDate.setDate(expirationDate.getDate() + pack.validityDays);

        // Get classes promise
        const getClassesInPack = () => {
            return new Promise((resolve) => {
                if (pack) {
                    resolve(pack.classes);
                }
            });
        }
        // Payload
        let payload = {
            purchaserID: userID,
            purchaserPhoneNumber: phone,
            classesInPack: await getClassesInPack(),
            notificationDate: notificationDate,
            expirationDate: expirationDate,
            date: new Date(),
        }

        // Send expiration entry
        await axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/expiration/create`, payload)
        .then((response) => {
            console.log('Expiration entry created');
        })
        .catch((error) => {
            console.log(error);
        });
    }

    // Update credits of the logguedInUser in the DB based on the purchase
    const updateCredits = async (pack, userID) => {
        await axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/user/updateUserCredits`, {
            ID: userID,
            credits: pack.classes,
            operation: 'add',
            creditsType: 'creditsAll'
        })
        .then((response) => {
            console.log('everything went well, thank you');
        })
        .catch((error) => {
            console.log(error);
        });
    }

    // Handle user purchase
    const handleUserPurchase = async (user, pack, phone) => {
        // Get pack
        let packData = null;
        await axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/pack/find`, {packID: pack})
        .then(async (response) => {
            packData = response.data.result[0];
            console.log('Pack found successfully');
        })
        .catch((error) => {
            console.log(error);
        });
        // Post purchase success
        await axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/sale/create`, {
            cart: [packData],
            total: packData.priceMXN,
            purchaserID: user,
            method: 'card',
            date: new Date(),
            time: new Date(),
            classes: packData.classes,
        })
        .then(async (response) => {
            // Step 1 :: Create expiration entry in DB
            await sendExpirationEntry(packData, user, phone);
            // Step 2 :: Update credits of the logguedInUser in the DB based on the purchase
            await updateCredits(packData, user);
            // Step 3 :: Set session storage
            sessionStorage.setItem('actionPerformed', 'true');
        })
        .catch((error) => {
            console.log(error);
        });
    }

     // Function to parse query parameters
    const getQueryParams = (search) => {
        return new URLSearchParams(search);
    };

    return (
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h4" align="center">
                    Compra completada
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <CheckCircleIcon sx={{ fontSize: 100, color: 'green' }} />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1" align="center">
                    Gracias por tu compra, tus clases han sido añadidos a tu perfil.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Button variant="contained" color="primary" fullWidth onClick={() => window.location.href = '/'}>
                    Ir a inicio
                </Button>
            </Grid>
        </Grid>
    );
}