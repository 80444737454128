import React, { useEffect, useState} from 'react';
import { 
    Grid,
    Typography, 
    Box, 
    Button,
    Grow,
    Container,
    CssBaseline,
    Divider
} from '@mui/material';
import axios from 'axios';
import CIcon from '@coreui/icons-react';
import * as icon from '@coreui/icons';

import { useTheme, ThemeProvider } from '@mui/material/styles';
import DialogSports from '../Dialog/Dialog';
import CodeVerification from './CodeVerification';

export default function VerifyPhone (props) {
    // State variables
    const [dialogProps, setDialogProps] = useState({ title: '', content: '', leftButton: '', rightButton: '', open: false });
    const [loading, isLoading] = useState(false);
    const [otpVerified, setOtpVerified] = useState(false);

    // Handle left button click
    const handleDialogLeftButton = () => {
        setDialogProps(
            { 
                title: '', 
                content: '', 
                leftButton: '', 
                rightButton: '', 
                open: false
            }
        );
        if (otpVerified) {
            props.setPhoneToVerify('');
            (props.logguedInUser != null || props.logguedInUser != undefined) ? window.location.reload() : props.setView('login');
        }
    }

    // Continue without verifying
    const continueWithoutVerifying = () => {
        props.setPhoneToVerify('');
        if (props.logguedInUser != null || props.logguedInUser != undefined) {
            props.logguedInUser.type === 'admin' ? props.setView('dashboard') : props.setView('schedule');
        } else {
            props.setView('login');
        }    
    }

    // Define theme variable
    const theme = useTheme();

    // Handle submit
    const handleCodeSubmit = async (code) => {
        // Verify if OTP is correct
        await axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/aws/verifyOTP`, { phoneNumber: props.phoneToVerify, otp: code })
            .then((res) => {
                if (res.data.success) {
                    // If OTP is correct, show dialog to inform user and set otpVerified to true
                    setDialogProps(
                        { 
                            title: 'Éxito ✨', 
                            content: 'Tu número de teléfono ha sido verificado, ahora te llevaremos a la página de inicio de sesión.', 
                            leftButton: 'Cerrar', 
                            rightButton: '', 
                            open: true
                        }
                    );
                    setOtpVerified(true);
                } else {
                    // If OTP is incorrect, show dialog
                    setDialogProps(
                        { 
                            title: 'Código incorrecto', 
                            content: 'El código de verificación que ingresaste es incorrecto. Por favor verifica el código e intenta de nuevo.', 
                            leftButton: 'Cerrar', 
                            rightButton: '', 
                            open: true
                        }
                    );
                    setOtpVerified(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
    
    // Return component
    return (
        <ThemeProvider theme={theme}>
            <Grow in={(props.view == 'verifyPhone')}>
                <Container component="main" maxWidth="md">
                    <CssBaseline />
                    {
                        props.phoneToVerify != '' &&
                        <Box
                            sx={{
                                marginTop: 3,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <DialogSports dialogProps={dialogProps} setDialogProps={setDialogProps} handleDialogLeftButton={handleDialogLeftButton} />
                           {/*  <img src={AuraLogo} style={{ height: '100px', margin: '20px' }}></img> */}
                            <Typography component="h1" variant="h5" sx={{ fontFamily: 'Qairo' }}>
                                Verificación de número de teléfono
                            </Typography>
                            <Divider sx={{ m: 2, borderWidth: '1px' }} flexItem orientation='horizontal' />
                            <Typography sx={{ fontFamily: 'PangramRegular', fontSize: '14px' }}>
                                Hemos enviado un mensaje de texto con un código de verificación a tu número de teléfono:
                            </Typography>
                            <Typography sx={{ fontFamily: 'PangramBold', fontSize: '18px', m: 1}}>
                                {`+52 ${props.phoneToVerify.substring(3, 5)} ${props.phoneToVerify.substring(5, 9)} ${props.phoneToVerify.substring(9)}`}
                            </Typography>
                            <Grid>
                                {/* six boxes input for verification code */}
                                <CodeVerification loading={loading} callback={handleCodeSubmit} phoneToVerify={props.phoneToVerify}/>
                            </Grid>
                            <Divider sx={{ m: 2, borderWidth: '1px' }} flexItem orientation='horizontal' />
                            <Box noValidate sx={{ mt: 1 }}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    sx={{ mb: 2, textTransform: 'none'}}
                                    onClick={() => continueWithoutVerifying()}
                                >
                                    Continuar sin verificar
                                </Button>
                            </Box>
                        </Box>
                    }
                </Container>
            </Grow>
        </ThemeProvider>
    )
}
