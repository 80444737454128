import React, { useState, useRef, useEffect } from "react";
import { Grid, Avatar, Fab, useTheme } from "@mui/material";
import CIcon from "@coreui/icons-react";
import * as icon from '@coreui/icons';
import axios from "axios";
import { compressImage } from "../../helpers/functions";

export default function ProfilePicture(props) {
    const [picture, setPicture] = useState('');
    const inputRef = useRef(null);
    const theme = useTheme();

    // Load picture from user or provided field
    useEffect(() => {
        loadPicture(props.userData.pictureURL);
    }, [props.userData]);

    // Load picture async function
    const loadPicture = async (picture) => {
        let pic = await getImageFromUserID(picture);
        setPicture(pic);
    }

    // Get image from user id
    const getImageFromUserID = (file) => {
        return new Promise((resolve) => {
            if (props.userData) {
                resolve(file);
            }
        });
    }

    // Image on change
    const imageOnChange = async (e) => {
        // Get compressed image
        let file = e.target.files[0];
        compressImage(file)
        .then((imgRes) => {
            // Display image
            setPicture(imgRes);
            // Update in database
            if (props.userData._id != '') {
                axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/${props.type}/update`, { "pictureURL" : imgRes, "_id" : props.userData._id }) 
                .then((res) => {
                    props.postAction(imgRes);
                })
                .catch((err) => {
                    console.log(err);
                });
            } else {
                props.postAction(imgRes);
            }
            
        })
        .catch((err) => {
            console.log(err);
        });   
    }

    return (
        <Grid item xs={12}>
            {
                (picture != '') ?
                    <Avatar
                        alt={'profile-picture'}
                        src={picture}
                        sx={{ width: 150, height: 150, margin: '0 auto' }}
                    />
                    :
                    <Avatar
                        sx={{
                            bgcolor: theme.palette.primary.main,
                            display: 'inline-flex',
                            height: 150,
                            width: 150,
                            fontSize: 60,
                            fontFamily: 'Qairo',
                            margin: 1
                        }}>
                        {
                            (props.logguedInUser) && String(props.logguedInUser.firstName.charAt(0)).toLocaleUpperCase() + String(props.logguedInUser.lastName.charAt(0)).toLocaleUpperCase()
                        }
                    </Avatar>
            }
            <Fab
                color='secondary'
                sx={{ display: 'block', textAlign: 'center', left: '20%', top: '-84px', position: 'relative' }}
                onClick={() => inputRef.current.click()}
            >
                <CIcon
                    icon={icon.cilPencil}
                    className='text-primary'
                    style={{ height: '25px', marginTop: '5px' }}
                />
            </Fab>
            <input type="file" accept="image/*" onChange={(e) => imageOnChange(e)} style={{ display: 'none' }} ref={inputRef} />
        </Grid>
    )
}