import React, { useState, useEffect } from "react";
import { 
    Grid, 
    Typography, 
    Button, 
    useTheme, 
    Divider,
    Box,
    Paper
} from "@mui/material";
import { alpha } from "@mui/material";
import CIcon from '@coreui/icons-react';
import * as icon from '@coreui/icons';

export default function PackCard (props) {
    const [isTouchDevice, setIsTouchDevice] = useState(false);
    // Use theme
    const theme = useTheme();

    useEffect(() => {
        isTouch();
    }, []);

    // Obtain displayValue from schema to display pack value
    // const displayValue = (value) => {
    //     if ((value.includes('pilates_reformer') && value.includes('sculpt_tone')) || value.includes('all')) {
    //         return 'Disciplinas: Pilates Reformer, Sculpt & Tone';
    //     } else if (value.includes('pilates_reformer')) {
    //         return 'Disciplina: Pilates Reformer';
    //     } else if (value.includes('sculpt_tone')) {
    //         return 'Disciplina: Sculpt & Tone';
    //     } else {
    //         return 'Paquete';
    //     }
    // }

    // Check if device is touch
    const isTouch = () => {
        if (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0)) {
            setIsTouchDevice(true);
        }
    }

    // RETURN
    return (
        <Paper elevation={6}>
            <Button 
                variant='text' 
                sx={{
                    backgroundColor: theme.palette.background.paper,  
                    textTransform: 'none',
                    transition: 'transform 0.2s',
                    width: '100%',
                    '&:hover': { 
                        transform: isTouchDevice ? 'none' : 'scale(1.05)',
                        backgroundColor: isTouchDevice ? theme.palette.background.paper : '#adb5bd',
                    },
                    p: 1
                }}
                onClick={() => props.setSelectedPack(props.pack)}
            >
                <Grid container direction='column'>
                    <Grid item xs>
                        {/* Price of pack */}
                        <Box textAlign={'initial'}>
                            <Typography
                                sx={{
                                    fontSize: '40px',
                                    textAlign: 'left',
                                    fontFamily: 'InterRegular',
                                    fontWeight: 'bold',
                                    display: 'inline',
                                    color: theme.palette.text.primary,
                                }}
                            >
                                {`$${props.pack.priceMXN}`}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '20px',
                                    textAlign: 'left',
                                    fontFamily: 'LarkenRegular',
                                    display: 'inline',
                                    color: theme.palette.text.primary,
                                }}
                            >
                                {` MXN`}
                            </Typography>
                        </Box>
                        {/* Class amount and expiration */}
                        <Typography
                            sx={{
                                fontSize: '16px',
                                textAlign: 'left',
                                opacity: 0.7,
                                pt: 0.5
                            }}
                        >
                            {`Acceso a ${props.pack.classes === 999 ? 'Ilimitadas' : props.pack.classes} clase(s) con validez de ${props.pack.validityDays} días`}
                        </Typography>
                    </Grid>
                    <Grid item xs textAlign={'left'}>
                        <Typography 
                            sx={{
                                fontSize: '16px', 
                                fontFamily: 'LarkenBold',
                                color: theme.palette.primary.main,
                                fontWeight: 'bold',
                                pt: 1
                            }}
                        >
                        {props.pack.description}</Typography>
                    </Grid>
                    <Grid item xs>
                        <Grid container direction='column' pt={1}>
                            <Divider sx={{m:1}}/>
                            <Grid item xs textAlign={'-webkit-right'}>
                                <Box
                                    sx={{
                                        backgroundImage: `linear-gradient(to right, ${theme.palette.primary.main}, ${alpha(theme.palette.primary.main, 0.6)})`,
                                        height: '50px',
                                        alignContent: 'center',
                                        textAlign: 'center',
                                    }}
                                >
                                    <CIcon 
                                        icon={icon.cilCart} 
                                        className='text-primary' 
                                        style={{
                                            height: '20px',
                                            pt: '30px', 
                                            color: '#FFF',
                                            display: 'inline',
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Button>
        </Paper>
    )
}