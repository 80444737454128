import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { colors } from './themes/themeCons';
import './App.css';
// Components
import { CssBaseline } from "@mui/material";
import Main from './components/Main';
import ComingSoon from './components/ComingSoon';
import PaymentCompletion from './components/PaymentCompletion/PaymentCompletion';
// Stripe
import { loadStripe } from "@stripe/stripe-js";
// Router
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// Legal
import TermsAndConditions from './legal/TermsAndConditions';
import PrivacyPolicy from './legal/PrivacyPolicy';

function App() {
  // Variables
  let darkMode = useMediaQuery('(prefers-color-scheme: dark)');

  // Theme
  let theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: darkMode ? 'dark' : 'light',
          primary: {
            main: darkMode ? '#bfc0c0' : '#000' ,
          },
          secondary: {
            main: '#FFFFFF',
          },
          background: {
            default: darkMode ? '#2b2927' : colors.white,
          },
          info: {
            main: '#857b61',
          },
        },
        spacing: 16,
        components: {
          MuiSwitch: {
            styleOverrides: {
              root: {
                width: 46,
                height: 27,
                padding: 0,
                margin: 8,
              },
              switchBase: {
                padding: 1,
                '&$checked, &$colorPrimary$checked, &$colorSecondary$checked': {
                  transform: 'translateX(16px)',
                  color: '#fff',
                  '& + $track': {
                    opacity: 1,
                    border: 'none',
                  },
                },
              },
              thumb: {
                width: 24,
                height: 24,
              },
              track: {
                borderRadius: 13,
                border: '1px solid #bdbdbd',
                backgroundColor: '#fafafa',
                opacity: 1,
                transition: 'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
              },
            },
          },
        },
        shape: {
          borderRadius: 0,
        },
        typography: {
          fontFamily: 'InterRegular'
        }
      }),
    [darkMode],
  );

  // Theme
  theme = createTheme(theme, {
    palette: {
        info: {
            main: theme.palette.secondary.main,
        },
    },
});

  // Render
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CssBaseline />
          <BrowserRouter>
            <Routes>
              <Route path='/' element={<Main darkMode={darkMode} loadStripe={null} />} />
              <Route path='/completion' element={<PaymentCompletion />} />
              <Route path='/termsandconditions' element={<TermsAndConditions backEnabled={true} />} />
              <Route path='/privacypolicy' element={<PrivacyPolicy backEnabled={true} />} />
            </Routes>
          </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
