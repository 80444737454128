import React, { useEffect, useState } from 'react';
import { 
    Grid, 
    Typography, 
    Divider, 
    useTheme, 
    CircularProgress, 
    Button, 
    Grow
} from '@mui/material';
import axios from 'axios';
import consts from '../../../consts/consts';

// Components
import TodayClassCard from '../../TodayClassCard/TodayClassCard';
import RegisterToClassModal from '../../RegularUser/RegisterToClassContent';

// Assets
import PilatesWoman from '../../../assets/images/empty_class.png';


export default function Today(props) {
    const [currentTime, setCurrentTime] = useState(new Date());
    const [myClasses, setMyClasses] = useState([]);
    const [isPM, setIsPM] = useState(false);
    const [loading, setLoading] = useState(true);

    // Update every time logguedInUser state changes
    useEffect(() => {
        if (props.logguedInUser) {
            getMyClasses();
            window.scrollTo(0, 0);
        }
    }, [props.logguedInUser, props.registerToClass]);

    // Update every time schedulesView state changes
    useEffect(() => {
        if (props.schedulesView === 'myClasses' && props.logguedInUser) {
            getMyClasses();
        }
    }, [props.schedulesView]);

    // Update time every second
    useEffect(() => {
        // Initial time update to avoid delay
        setCurrentTime(new Date());
        setIsPM(currentTime.getHours() >= 12);
        // Update time every second
        const timer = setInterval(() => {
            setCurrentTime(new Date());
            setIsPM(currentTime.getHours() >= 12);
        }, 10000000); // Update every 10 seconds

        return () => {
            clearInterval(timer); // Clean up the timer when the component unmounts
        };
    }, []);

    // Obtain classes where logged in user is participant and day is today
    const getMyClasses = async () => {
        // Set loading
        setLoading(true);
        // Obtain today's Date in ISO format
        let today = new Date();
        today.setHours(0, 0, 0, 0);
        // Request to DB
        await axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/schedule/findbyParticipant`, { "participantID" : props.logguedInUser._id, "date" : today })
        .then((res) => {
            setMyClasses(res.data.result);
            setLoading(false);
        })
        .catch((err) => {
            console.log(err);
            setLoading(false);
        });
    }

    // Theme
    const theme = useTheme();

    // Get day name
    const getDayName = (day) => {
        const dayNames = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
        return dayNames[day];
    };

    // Get month name
    const getMonth = (month) => {
        const monthNames = consts.months
        return monthNames[month].displayName;
    };

    // Handle new class
    let handleNewClass = () => {
        props.setRegisterToClass(true);
    }

    // Return the component
    return (
        <>
            <Grid 
                container 
                direction='column'
            >
                {/* Say hello to user */}
                <Grid item xs={1} textAlign={'left'}>
                    <Typography
                        sx={{
                            m: '5px 20px',
                            fontFamily: 'LarkenLight',
                            fontSize: 25,
                            fontWeight: 'bold',
                            mt: 1
                        }}
                    >
                        {`Hola ${props.logguedInUser && props.logguedInUser.firstName} 🫰🏻`}
                    </Typography>
                </Grid>
                {/* Today's day view */}
                <Grid item xs={4} m={1.2}>
                    <Grid container direction='column'>
                        <Grid item xs={6} textAlign={'left'}>
                            <Typography>
                                {`${getDayName(currentTime.getDay())} ${currentTime.getDate()} de ${getMonth(currentTime.getMonth())} del ${currentTime.getFullYear()}`}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} alignContent={'center'} m={1}>
                            <Divider orientation='horizontal' sx={{width: '95%', borderColor: theme.palette.text.primary}}/>
                        </Grid>
                        <Grid item xs={5} alignContent={'left'} textAlign={'left'}>
                            <Typography sx={{ fontSize: 25, lineHeight: 1 }}>{`${isPM ? ((currentTime.getHours() === 12) ? (currentTime.getHours()) : (currentTime.getHours() - 12)) : currentTime.getHours()}:${currentTime.getMinutes().toString().padStart(2, '0')} ${(isPM ? 'PM': 'AM')}`}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                {/* Today's classes stack */}
                <Grid 
                    item 
                    xs={7} 
                    textAlign={'left'}
                >
                    {
                        loading ?
                        <CircularProgress />
                        :
                        <>
                        {myClasses.map((classData, index) => (
                            <Grow in={true} style={{ transitionDelay: `${50 * index}ms` }}>
                                <Grid item xs={12} key={index} sx={{overflow: 'hidden'}}>
                                    <TodayClassCard 
                                        coaches={props.coaches} 
                                        classData={classData} 
                                        logguedInUser={props.logguedInUser}
                                        getMyClasses={getMyClasses}
                                    />
                                </Grid>
                            </Grow>
                        ))}
                        {
                            myClasses.length === 0 && 
                            <Grid 
                                container 
                                direction='column' 
                            >
                                <Grid item xs={1}>
                                    <Typography 
                                        sx={{
                                            m: 2, 
                                            fontFamily: 'LarkenLight',
                                            width: '85%',
                                            textAlign: 'center',
                                        }}
                                    >Aquí aparecerán tus clases del día de hoy</Typography>
                                </Grid>
                                <Grid item xs={1} textAlign={'center'}>
                                    <Button 
                                        variant='contained'
                                        color='primary'
                                        sx={{
                                            p: 0.75,
                                            textTransform: 'none',
                                            color: '#FFF',
                                            fontSize: 15,
                                            mb: '13vh'
                                        }}
                                        onClick={() => handleNewClass()}
                                    >Unirme a una clase</Button>
                                </Grid>
                                <Grid item xs={10} textAlign={'center'}>
                                    <img src={PilatesWoman} style={{width: '80vw'}}/>
                                </Grid>
                                
                            </Grid>
                        }
                        </>
                    }
                </Grid> 
            </Grid>
        </>
    );
}
