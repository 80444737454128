import * as React from 'react';
import { Global } from '@emotion/react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { grey } from '@mui/material/colors';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import RegisterToClassContent from '../../RegularUser/RegisterToClassContent';
// Helpers
import { checkExpiringCredits } from '../../../helpers/functions.js';

const drawerBleeding = 16;

const Root = styled('div')(({ theme }) => ({
    height: '100%',
    backgroundColor: grey[100],
    ...theme.applyStyles('dark', {
        backgroundColor: theme.palette.background.default,
    }),
}));

const StyledBox = styled('div')(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.applyStyles('dark', {
        backgroundColor: grey[800],
    }),
}));

const Puller = styled('div')(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: grey[300],
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)',
    ...theme.applyStyles('dark', {
        backgroundColor: grey[900],
    }),
}));

function RegisterToClass(props) {
    const { window } = props;
    const [open, setOpen] = React.useState(props.RegisterToClass);

    React.useEffect(() => {
        setOpen(props.registerToClass);
        checkExpiringCredits(JSON.stringify(props.logguedInUser));
    }, [props.registerToClass]);

    React.useEffect(() => {
        (open === false) && props.setRegisterToClass(false);
    }, [open]);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    // This is used only for the example
    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Root>
            <CssBaseline />
            <Global
                styles={{
                '.MuiDrawer-root > .MuiPaper-root': {
                    height: `85%`,
                    overflow: 'visible',
                },
                }}
            />
            <SwipeableDrawer
                container={container}
                anchor="bottom"
                open={open}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                swipeAreaWidth={drawerBleeding}
                disableSwipeToOpen={false}
                ModalProps={{
                keepMounted: true,
                }}
            >
            <StyledBox 
                sx={{ 
                    px: 2, 
                    pb: 2, 
                    
                    height: '100%', 
                    overflow: 'auto'
                }}
            >
                <RegisterToClassContent 
                    registerToClass={props.registerToClass} 
                    setRegisterToClass={props.setRegisterToClass}
                    logguedInUser={props.logguedInUser}
                    setLogguedInUser={props.setLogguedInUser}
                    coaches={props.coaches}
                    refreshUserData={props.refreshUserData}
                />
            </StyledBox>
        </SwipeableDrawer>
    </Root>
    );
}

export default RegisterToClass;