import React, { useState } from 'react';
import { Typography, Grid, Button } from "@mui/material";
import mammoth from 'mammoth';
import FAQDocx from '../legal/documents/faq.docx';
import Logo from '../assets/Logo';
import CIcon from '@coreui/icons-react';
import * as icon from '@coreui/icons';

export default function FAQ (props) {
  const [htmlContent, setHtmlContent] = useState('');

    // go to root path
    const goBack = () => {
        window.location.href = '/';
    }

  const loadDocument = async () => {
    try {
      const response = await fetch(FAQDocx);
      const arrayBuffer = await response.arrayBuffer();
      const { value } = await mammoth.convertToHtml({ arrayBuffer});
      setHtmlContent(value);
    } catch (error) {
      console.error('Error loading document:', error);
    }
  };

  React.useEffect(() => {
    loadDocument();
  }, []);

  return (
    <div style={{margin: '30px'}}>
        {/* Back button */}
        {
            props.backEnabled &&
            <Grid alignSelf={'flex-start'} margin={2} textAlign={'left'}>
                <Button onClick={() => goBack()}>
                    <CIcon 
                        icon={icon.cilArrowLeft} 
                        className='text-primary' 
                        style={{height: '20px', marginRight: '20px'}}
                    />
                    <Typography sx={{textTransform: 'none'}}>Atrás</Typography>
                </Button>
            </Grid>
        }
        <Logo />
        <Typography variant="h3" sx={{m:2}}>FAQ</Typography>
            <div
                className="word-content"
                dangerouslySetInnerHTML={{ __html: htmlContent }}
            ></div>
        </div>
  );
};