import React from "react";
import { Grid, Typography } from '@mui/material';
import DashboardButton from "./DashboardButton";
import Birthdays from "./Birthdays";
import consts from "../../consts/consts";

export default function Dashboard(props) {

    return (
        <>
            <Grid container direction='column' padding={2} spacing={1}>
                {/* Say hello to admin */}
                <Grid xs={2} item>
                    <Typography
                        sx={{
                            fontSize: '40px',
                            fontWeight: 'bold',
                            textAlign: 'left',
                            fontFamily: 'LarkenRegular'
                        }}
                    >
                        {`Hola ${props.logguedInUser.firstName} 👋🏻`}
                    </Typography>
                </Grid>
                {/* Display categories */}
                <Grid xs={4} item>
                    <Grid container direction='row' spacing={1}>
                        {consts.adminCategories.map((category => (
                            <Grid xs item>
                                <DashboardButton 
                                    id={category.id}
                                    setAdminView={props.setAdminView}
                                    icon={category.icon}
                                    displayName={category.displayName}
                                />
                            </Grid>
                        )))}
                    </Grid>
                </Grid>
                {/* Birthdays */}
                <Grid xs={4} item>
                    <Birthdays/>
                </Grid>
            </Grid>
        </>
    )
}