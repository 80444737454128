import React from 'react';
import consts from '../consts/consts';
import { 
    Grid, 
    FormControl, 
    InputLabel, 
    Select, 
    MenuItem, 
    Typography, 
    TextField, 
    FormControlLabel, 
    Switch, 
    RadioGroup, 
    Radio, 
    FormLabel,
    Autocomplete
} from '@mui/material';
// MUI-X Components
import { LocalizationProvider, TimePicker, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ProfilePicture from '../components/ProfilePicture/ProfilePicture';

const renderField = (field, formData, handleChange, errors, handleAutocompleteChange, options, excludedKeys, coaches, adminView, handleAutocompleteOptionChange) => {
    const fieldKey = field[0];
    const fieldValue = field[1];

    // Convert ISO Date to local Date
    const convertISODateToLocalDate = (isoDateString) => {
        const date = new Date(isoDateString);
        const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
        return localDate;
    }

    // Get coaches array
    let getCoachesArray = () => {
        let coachesArray = [];
        coaches.forEach(coach => {
            coachesArray.push({ displayValue: `${coach.firstName} ${coach.lastName}`, value: coach._id });
        });
        return coachesArray;
    }

    // Get options for select fields
    const getOptions = (fieldName) => {
        switch(fieldName) {
            case 'shoeSize': return consts.shoeSizes;
            case 'birthdayMonthNumber': return consts.months;
            case 'month' : return consts.months;
            case 'purchaseMonthNumber' : return consts.months;
            case 'status' : return consts.statuses;
            case 'discipline' : return consts.disciplines;
            case 'teacherID' : return getCoachesArray();
        }
    }

    // Check for excluded keys, if found, don't show anything (no field available)
    if (excludedKeys.includes(fieldKey)) {
        return null;
    }

    // Check for type of input
    if (fieldValue.subtype === 'select') {
        // Code to retrieve Select element
        let options = fieldValue.options ? fieldValue.options : getOptions(fieldKey);
        return (
            <Grid item xs={12} sm={6} key={fieldKey}>
                <FormControl fullWidth>
                    <InputLabel id={`${fieldKey}-label`}>{fieldValue.readableValue}</InputLabel>
                    <Select
                        labelId={`${fieldKey}-label`}
                        id={fieldKey}
                        value={formData[fieldKey] || ''}
                        label={fieldValue.readableValue}
                        onChange={(e) => handleChange(fieldKey, e.target.value)}
                    >
                        {options && options.map((option, index) => (
                            <MenuItem key={index} value={option.value}>{option.displayValue}</MenuItem>
                        ))}
                    </Select>
                    {errors[fieldKey] && <Typography color="error">{errors[fieldKey]}</Typography>}
                </FormControl>
            </Grid>
        );
    } else if (fieldValue.subtype === 'date') {
        // Code to retrieve Date element
        return (
            <Grid item xs={12} sm={6} key={fieldKey}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label={fieldValue.readableValue}
                        value={formData[fieldKey] ? convertISODateToLocalDate(formData[fieldKey]) : null}
                        sx={{width: '100%'}}
                        timezone="America/Mexico_City"
                        onChange={(newValue) => handleChange(fieldKey, newValue)}
                        renderInput={(params) => <TextField {...params} />}
                        format="dd/MM/yyyy"
                        views={['year', 'month', 'day']}
                        openTo={'year'}
                        maxDate={fieldKey === 'birthday' ? new Date() : null}
                    />
                </LocalizationProvider>
            </Grid>
        );
    }  else if (fieldValue.subtype === 'time') {
        // Code to retrieve Time element
            return (
                <Grid item xs={12} sm={6} key={fieldKey}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                            label={fieldValue.readableValue}
                            value={formData[fieldKey] ? new Date(formData[fieldKey]) : null}
                            sx={{width: '100%'}}
                            timezone="America/Mexico_City"
                            onChange={(newValue) => handleChange(fieldKey, newValue)}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </Grid>
            );
        } else if (fieldValue.subtype === 'boolean') {
        // Code to retrieve Boolean element
        return (
            <Grid item xs={12} sm={6} key={fieldKey}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={formData[fieldKey] || false}
                            onChange={(e) => handleChange(fieldKey, e.target.checked)}
                            name={fieldKey}
                            color="primary"
                            defaultValue={false}
                        />
                    }
                    label={fieldValue.readableValue}
                />
                {errors[fieldKey] && <Typography color="error">{errors[fieldKey]}</Typography>}
            </Grid>
        );
    } else if (fieldValue.subtype === 'number') {
        // Code to retrieve Number element
        return (
            <Grid item xs={12} sm={6} key={fieldKey}>
                <TextField 
                    id={fieldKey}
                    label={fieldValue.readableValue}
                    variant="standard"
                    fullWidth
                    value={formData[fieldKey]}
                    onChange={(e) => handleChange(fieldKey, e.target.value)}
                    error={!!errors[fieldKey]}
                    helperText={errors[fieldKey]}
                    type={fieldKey === 'credits' ? 'number' : 'text'}
                    inputProps={{ min: 0 }}
                />
            </Grid>
        );
    } else if (fieldValue.subtype === 'autocomplete') {
        // Code to retrieve Autocomplete element
        return (
            <Grid item xs={12} sm={6} key={fieldKey}>
                <Autocomplete
                    id={fieldKey}
                    options={options}
                    getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                    style={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label={fieldValue.readableValue} variant="outlined" />}
                    onInputChange={(event, newInputValue) => {
                        handleAutocompleteChange(fieldKey, newInputValue);
                    }}
                    onChange={(event, newValue) => {
                        handleAutocompleteOptionChange(fieldKey, newValue);
                    }}
                />
            </Grid>
        );
    } else if (fieldValue.subtype === 'radio') {
        // Code to retrieve Radio element
        return (
            <Grid item xs={12} sm={6} key={fieldKey}>
                <FormControl>
                    <FormLabel id={`${fieldKey}-radio`}>{fieldValue.readableValue}</FormLabel>
                    <RadioGroup
                        aria-labelledby={`${fieldKey}-radio-group-label`}
                        defaultValue="female"
                        name={`${fieldKey}-radio-buttons-group`}
                        onChange={(e) => handleChange(fieldKey, e.target.value)}
                    >
                        {
                            fieldValue.options.map((option, index) => {
                                return (
                                    <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                                        <FormControlLabel 
                                            value={option.value}
                                            checked={(formData[fieldKey] === option.value) || (formData[fieldKey] === 'other' && formData[`${fieldKey}-tf`] === option.displayValue)}
                                            control={<Radio />}
                                            label={option.displayValue}
                                            style={{ marginRight: '8px' }}
                                        />
                                        {
                                            option.value === 'other' &&
                                            <TextField
                                                id={`${fieldKey}-tf`}
                                                label="Other"
                                                variant="standard"
                                                value={(formData[`${fieldKey}-tf`] || '') || (formData[fieldKey] === 'other' ? '' : option.displayValue)}
                                                onChange={(e) => handleChange(`${fieldKey}-tf`, e.target.value)}
                                                error={!!errors[`${fieldKey}-tf`]}
                                                helperText={errors[`${fieldKey}-tf`]}
                                                style={{ flex: 1 }}
                                            />
                                        }
                                    </div>
                                )
                            })
                        }
                    </RadioGroup>
                </FormControl>
                {errors[fieldKey] && <Typography color="error">{errors[fieldKey]}</Typography>}
            </Grid>
        );
    } else if (fieldValue.subtype === 'image') {
        // Code to retrieve image element
        return (
            <ProfilePicture
                key={fieldKey}
                userData={formData}
                postAction={(base64image) => handleChange(fieldKey, base64image)}
                type={(adminView === 'user' ? 'user' : 'coach')}
            />
        )
    } else {
        // Code to retrieve Text element
        return (
            <Grid item xs={12} sm={6} key={fieldKey}>
                <TextField
                    id={fieldKey}
                    label={fieldValue.readableValue}
                    variant="standard"
                    fullWidth
                    value={formData[fieldKey] || ''}
                    onChange={(e) => handleChange(fieldKey, e.target.value)}
                    error={!!errors[fieldKey]}
                    helperText={errors[fieldKey]}
                />
            </Grid>
        );
    }
}

export default renderField;