import React, { useEffect } from 'react';
import {
    Grid, 
    Typography, 
    useTheme,
    CircularProgress,
    Box
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import ScheduleCard from '../ScheduleCard/ScheduleCard';
import es from 'date-fns/locale/es';
import axios from 'axios';

export default function ScheduleCalendar(props) {
    const [date, setDate] = React.useState(new Date());
    const [availableClasses, setAvailableClasses] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    // const [classType, setClassType] = React.useState(2); // <--- 0 is for sculpt/tone, 1 is for pilates reformer & sculpt/tone, 2 is for all.
    const [filteredClasses, setFilteredClasses] = React.useState([]);

    // Get theme
    const theme = useTheme();

    // Hear for changes of date to retrieve classes of selected date
    useEffect(() => { 
        getDayClasses(date);
    }, [date]);

    useEffect(() => {
        setFilteredClasses(availableClasses);
    }, [availableClasses]);

    // Hear for changes of classType to filter classes
    // useEffect(() => {
    //     filterClasses(classType);
    // }, [classType, availableClasses]);

    // Function to filter classes by type
    // const filterClasses = (type) => {
    //     if (type === 2) {
    //         setFilteredClasses(availableClasses);
    //     } else if (type === 1) {
    //         setFilteredClasses(availableClasses.filter((classItem) => classItem.discipline.includes('sculpt_tone')));
    //     } else if (type === 0) {
    //         setFilteredClasses(availableClasses.filter((classItem) => classItem.discipline.includes('pilates_reformer')));
    //     }
    // };

    // Convert Central Standard Time to UTC for saving in database
    const convertToUTCString = (date) => {
        const utcDate = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
        date.getUTCDate(), date.getUTCHours(),
        date.getUTCMinutes(), date.getUTCSeconds());
        return new Date(utcDate).toISOString();
    };

    // Custom ToggleButton
    // const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    //     [`& .${toggleButtonGroupClasses.grouped}`]: {
    //         margin: theme.spacing(0.3),
    //         border: 1,
    //         [`&.${toggleButtonGroupClasses.disabled}`]: {
    //             border: 1,
    //             borderRadius: 0,
    //         },
    //         [`&.${toggleButtonGroupClasses.selected}`]: {
    //             border: 1,
    //             borderRadius: 10,
    //             backgroundColor: theme.palette.primary.main,
    //             '&:hover': {
    //                 backgroundColor: theme.palette.primary.main, // Keep the same background color on hover
    //             },
    //         },
    //         '&:hover': {
    //             backgroundColor: 'unset', // Disable hover color changing
    //         },
    //     },
    //     [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]:
    //         {
    //             borderLeft: '1px solid transparent',
    //             color: theme.palette.text.primary,
    //         },
    // }));

    // Function to retrieve classes of selected date
    const getDayClasses = async () => {
        setLoading(true);
        // Set time to 0 to avoid timezone issues
        date.setHours(0, 0, 0, 0);
        // Delete time from the newValue to avoid timezone issues
        let queryDate = convertToUTCString(new Date(date));
        // Search for classes of selected date
        await axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/schedule/find`, { date: queryDate })
        .then((res) => {
            // Order results based on time object (date value but we only care about time)
            let sortedResults = res.data.result.sort((a, b) => {
                return new Date(a.time) - new Date(b.time);
            });
            
            setAvailableClasses(sortedResults);
            setLoading(false);
        })
        .catch((err) => {   
            console.log(err);
            setLoading(false);
        });
    };


    // Return the component
    return (
        <>
            <Grid container direction='row'>
                <Grid item xs={12} sm={6} sx={{width: {xs: '100%', sm: '50%'}}}>
                    <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}>
                        <StaticDatePicker
                            displayStaticWrapperAs="desktop"
                            label="Week picker"
                            showDaysOutsideCurrentMonth={true}
                            value={date}
                            onChange={(newValue) => {
                                setDate(newValue);
                            }}
                            sx={{backgroundColor: 'unset'}}
                            disablePast
                            inputFormat="'Week of' MMM d"
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6} sx={{width: {xs: '100%', sm: '50%'}}}>
                    <Box
                        sx={{
                            m: 1
                        }}
                    >
                    </Box>
                    {loading 
                    ? 
                    <CircularProgress size={80} sx={{margin: 5}}/> 
                    : 
                        (filteredClasses.length > 0 && !loading) 
                        ?
                        <Grid container spacing={2}>
                            {filteredClasses.map((classItem, index) => (
                                <Grid item xs={12} key={classItem._id}>
                                    <ScheduleCard 
                                        classData={classItem} 
                                        isDarkMode={props.isDarkMode}
                                        setSelectedClass={props.setSelectedClass}
                                        coaches={props.coaches}
                                        logguedInUser={props.logguedInUser}
                                    />
                                </Grid>
                            ))}
                        </Grid> 
                        :
                        <Typography variant="h6" align="center" sx={{m:2}}>
                            Parece que no hay clases para el día que seleccionaste 🤔, intenta con otro
                        </Typography>
                    }
                </Grid>
            </Grid>
        </>
    )
}
