import React from "react";
import { 
    AppBar, 
    Toolbar, 
    Box, 
    Typography, 
    Button, 
    Drawer,
    List,
    Divider,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    useTheme,
    Popover,
    MenuItem,
    Grid
} from "@mui/material";
import { 
    Menu,
    Logout,
} from "@mui/icons-material";


import CIcon from "@coreui/icons-react";
import * as icon from '@coreui/icons';

export default function AppTopBarLanding(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const theme = useTheme();
    const [state, setState] = React.useState({
        left: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
    
        setState({ ...state, [anchor]: open });
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    let handleLoginButton = () => {
        if (props.logguedInUser != null) {
            localStorage.removeItem('ssmx-LoggedInUser');
            props.setLogguedInUser(null);
            props.setView('landing');
        } else {
            props.setView('login');
        }
    }

    const scrollToElementName = (elementName) => {
        document.getElementById(elementName).scrollIntoView({behavior: 'smooth'});
    }
    
    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
        <List>
                {props.admin
                ?
                /* Admin side buttons */
                <>
                    <ListItem key={'dashboard'} disablePadding>
                        <ListItemButton onClick={() =>  props.setView('dashboard')}>
                            <ListItemIcon>
                                <CIcon icon={icon.cilGrid} style={{width: '25px'}}/>
                            </ListItemIcon>
                            <ListItemText primary={'Dashboard'} />
                        </ListItemButton>
                    </ListItem>
                </>
                :
                // Regular user side buttons
                <>
                    <ListItem key={'ourClasses'} disablePadding>
                        <ListItemButton onClick={() =>  scrollToElementName('ourClasses')}>
                            <ListItemIcon>
                                <CIcon icon={icon.cilLibrary} style={{width: '25px'}}/>
                            </ListItemIcon>
                            <ListItemText primary={'Nuestras clases'} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem key={'memberships'} disablePadding>
                        <ListItemButton onClick={() =>  scrollToElementName('memberships')}>
                            <ListItemIcon>
                                <CIcon icon={icon.cilInbox} style={{width: '25px'}}/>
                            </ListItemIcon>
                            <ListItemText primary={'Membresías'} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem key={'calendar'} disablePadding>
                        <ListItemButton onClick={() =>  scrollToElementName('calendar')}>
                            <ListItemIcon>
                                <CIcon icon={icon.cilCalendar} style={{width: '25px'}}/>
                            </ListItemIcon>
                            <ListItemText primary={'Calendario'} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem key={'contact'} disablePadding>
                        <ListItemButton onClick={() =>  scrollToElementName('contact')}>
                            <ListItemIcon>
                                <CIcon icon={icon.cilContact} style={{width: '25px'}}/>
                            </ListItemIcon>
                            <ListItemText primary={'Contacto'} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem key={'faqs'} disablePadding>
                        <ListItemButton onClick={() =>  scrollToElementName('faqs')}>
                            <ListItemIcon>
                                <CIcon icon={icon.cilInfo} style={{width: '25px'}}/>
                            </ListItemIcon>
                            <ListItemText primary={'FAQS'} />
                        </ListItemButton>
                    </ListItem>
                </>
                }
                
        </List>
        <Divider />
            <ListItem key={'closeSession'} disablePadding>
                <ListItemButton onClick={() => handleLoginButton()}>
                    <ListItemIcon>
                        <Logout sx={{ color: theme.palette.primary.main }}/>
                    </ListItemIcon>
                    <ListItemText primary={props.logguedInUser ? 'Cerrar sesión' : 'Iniciar sesión'} sx={{ color: theme.palette.primary.main }}/>
                </ListItemButton>
            </ListItem>
        </Box>
    );

    const Logo = () => {
        return (
            <Grid item xs alignContent={'center'}>
                {/* Sculpt studio MX logo */}
                <Typography
                    sx={{
                        fontFamily: 'LarkenRegular',
                        fontSize: '30px',
                        lineHeight: '1'
                    }}
                >SCULPT</Typography>
                <Typography
                    sx={{
                        fontFamily: 'InterRegular',
                        fontSize: '8px',
                        letterSpacing: '0.5em',
                    }}
                >WELLNESS STUDIO</Typography>
            </Grid>
        );
    }

    return (
        <AppBar 
            position={"fixed"}
            sx={{ bgcolor: '#000' }}
        >
            <Toolbar>
                <Button onClick={toggleDrawer('left', true)} sx={{flexGrow: 1}}>
                    <Menu sx={{ color: '#FFF'}}/>
                </Button>
                <Box
                    sx={{
                        height: 50,
                        width: 200,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexGrow: 20
                    }}
                >
                    <Logo />
                </Box>
                {props.logguedInUser
                ?
                // Admin loggued in, use fab icon with user icon
                    <>
                        <Button
                            color='secondary' 
                            variant='outlined'
                            onClick={handleClick} 
                            sx={{
                                textTransform: 'none', 
                                flexGrow: 1, 
                                maxHeight: '35px', 
                                textWrap: 'nowrap',
                                borderRadius: '20px',
                            }}
                            >
                                {props.logguedInUser.pictureURL ?
                                    <img src={props.logguedInUser.pictureURL} style={{width: '25px', height: '25px', margin: '10px', objectFit: 'cover', borderRadius: '25px'}}/>    
                                :
                                    <CIcon icon={icon.cilUser} style={{width: '25px', marginRight: '10px'}}/>
                                }
                            <Typography>{props.logguedInUser.firstName}</Typography>
                        </Button>
                        <Popover
                            open={Boolean(anchorEl)}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <MenuItem onClick={() => {handleClose(); props.setView('myProfile');}}>Mi Perfil</MenuItem>
                            {props.logguedInUser.type == 'admin' && <MenuItem onClick={() => {handleClose(); props.setView('dashboard');}}>Dashboard</MenuItem>} 
                        </Popover>
                    </>
                :
                    <Button 
                        color='secondary' 
                        variant={props.logguedInUser ? 'outlined' : 'contained'}
                        onClick={() => handleLoginButton()}
                        sx={{
                            textTransform: 'none', 
                            flexGrow: 1, 
                            maxHeight: '35px', 
                            textWrap: 'nowrap',
                        }}
                        >
                        {props.logguedInUser ? 'Cerrar sesión' : 'Iniciar sesión'}
                    </Button>
                }
            </Toolbar>
            <Drawer
                anchor={'left'}
                open={state['left']}
                onClose={toggleDrawer('left', false)}
            >
                {list('left')}
            </Drawer>
        </AppBar>
    )
}