import React, { useEffect, useState } from "react";
import { 
    Grid, 
    Typography, 
    useTheme, 
    Box, 
    Paper, 
    CircularProgress, 
    Tabs,
    Tab,
    Grow
} from "@mui/material";
import PackCard from "../../PackCard/PackCard.jsx";
import CheckoutModal from "../../CheckoutModal/CheckoutModal.jsx";
import axios from 'axios';
import SwipeableViews from "react-swipeable-views";
import MembershipCard from "../../MembershipCard/MembershipCard.jsx";
// Helpers
import { checkExpiringCredits } from "../../../helpers/functions.js";

export default function Credits (props) {
    const [packs, setPacks] = useState([]);
    const [selectedPack, setSelectedPack] = useState(null);
    const [packType, setPackType] = React.useState(2); // <--- 0 is for sculpt/tone, 1 is for pilates reformer & sculpt/tone, 2 is for all.
    const [filteredPacks, setFilteredPacks] = React.useState([]);
    const [loading, setLoading] = useState(false);
    const [value, setValue] = React.useState(0);
    const [totalClasses, setTotalClasses] = useState(0);
    const theme = useTheme();


    // Get packs from the DB
    useEffect(() => {
        getPacks();
        sessionStorage.setItem('actionPerformed', 'false');
        checkExpiringCredits(JSON.stringify(props.logguedInUser));
    }, []);

    // Hear for changes in logguedInUser
    useEffect(() => {
        if (props.logguedInUser.packs) {
            let total = 0;
            props.logguedInUser.packs.forEach(pack => {
                total += Number(pack.remainingClasses);
            });
            setTotalClasses(total);
        }
    }, [props.logguedInUser]);

    // Hear for changes of classType to filter classes
    useEffect(() => {
        filterPacks(packType);
    }, [packType, packs]);

    // Function to filter classes by type
    const filterPacks = (type) => {
        if (type === 2) {
            setFilteredPacks(packs);
        } else if (type === 1) {
            setFilteredPacks(packs.filter((classItem) => classItem.discipline.includes('sculpt_tone')));
        } else if (type === 0) {
            setFilteredPacks(packs.filter((classItem) => classItem.discipline.includes('pilates_reformer')));
        }
    };

    // Get packs from the DB
    const getPacks = async () => {
        setLoading(true);
        await axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/pack/getAll`)
        .then((response) => {
            setPacks(response.data.result);
            setLoading(false);
        })
        .catch((error) => {
            console.log(error);
            setLoading(false);
        });
    }

    // Handle tab change
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };


    // Custom tab panel component
    const CustomTabPanel = (props) => {
        const { children, value, index, ...other } = props;
        return (
            <Box
                role="tabpanel"
                hidden={value !== index}
                id={`credits-type-tabpanel-${index}`}
                aria-labelledby={`credits-type-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box>
                        {children}
                    </Box>
                )}
            </Box>
        );
    }

    return (
        <Grid container direction='column'>
            {/* Checkout modal */}
            <CheckoutModal 
                selectedPack={selectedPack} 
                setSelectedPack={setSelectedPack} 
                logguedInUser={props.logguedInUser} 
                setLogguedInUser={props.setLogguedInUser}
                loadStripe={props.loadStripe}
            />
            
            {/* Tabs */}
            <Box>
                <Tabs
                    value={value}
                    onChange={handleTabChange}
                    aria-label="credits-type-tabs"
                    variant="fullWidth"
                >
                    <Tab label="Mis Membresias" sx={{fontFamily: 'InterRegular', fontSize: '15px', color: props.darkMode ? '#FFF' : '#000', textTransform: 'none'}}/>
                    <Tab label="Comprar" sx={{fontFamily: 'InterRegular', fontSize: '15px', color: props.darkMode ? '#FFF' : '#000', textTransform: 'none'}}/>
                </Tabs>
            </Box>
            {/* Swipeable Views */}
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={(index) => setValue(index)}
            >
                {/* My Packages (client wants to name them membresias) */}
                <CustomTabPanel value={value} index={0} dir={theme.direction}>
                    <Box>
                        <Typography
                            sx={{
                                fontFamily: 'InterRegular', 
                                fontSize: '15px', 
                                color: props.darkMode ? '#FFF' : '#000', 
                                opacity: 0.6, 
                                textAlign: 'center',
                                p: 2
                            }}
                        >
                            {`Tienes un total de ${(totalClasses >= 800) ? 'Ilimitadas' : totalClasses} clases disponibles`}
                        </Typography>
                    </Box>
                    {
                        !loading ?
                            (props.logguedInUser.packs && props.logguedInUser.packs.length > 0) ? (
                                <Grid container direction='row' p={1} spacing={1}>
                                {
                                    props.logguedInUser.packs.map((pack, index) => (
                                        <MembershipCard key={index} pack={pack} setSelectedPack={setSelectedPack} admin={false}/>
                                    ))
                                }
                                </Grid>
                            ) : (
                                <Box 
                                    sx={{
                                        display: 'flex', 
                                        justifyContent: 'center', 
                                        alignItems: 'center', 
                                        height: '100%', 
                                        width: '100%',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontFamily: 'InterRegular', 
                                            fontSize: '15px', 
                                            color: props.darkMode ? '#FFF' : '#000', 
                                            opacity: 0.6, 
                                            textAlign: 'center',
                                            p: 2,
                                            mt: '20vh'
                                        }}
                                    >
                                        Aquí aparecerán tus membresías, ¿que te parece si comenzamos comprando una?
                                        Toca la sección "Comprar" ☝🏻 y explora nuestras membresías disponibles.
                                    </Typography>
                                </Box>
                            )
                        :
                        <CircularProgress size={60} sx={{m: 2}}/>
                    }
                </CustomTabPanel>


                {/* Packages for purchase */}
                <CustomTabPanel value={value} index={1} dir={theme.direction}>
                    <Grid container direction='row' sx={{justifyContent: 'center'}} spacing={2} p={1}>
                        {
                            !loading ?
                                filteredPacks.length > 0 ? (
                                    filteredPacks.map((pack, index) => (
                                        <Grow in={true} style={{ transitionDelay: `${50 * index}ms` }}>
                                            <Grid item xs minWidth={'300px'}>
                                                <PackCard key={index} pack={pack} setSelectedPack={setSelectedPack}/>
                                            </Grid>
                                        </Grow>
                                    ))
                                ) : (
                                    <Typography sx={{fontFamily: 'PangramBold', fontSize: '15px', color: props.darkMode ? '#FFF' : '#000', opacity: 0.6, textAlign: 'center', p: 2, ml: 2}}>
                                        Lo sentimos no hay paquetes disponibles aún, por favor intenta más tarde.
                                    </Typography>
                                )
                            :
                            <CircularProgress size={60} sx={{m: 2}}/>
                        }
                    </Grid>
                </CustomTabPanel>
            </SwipeableViews>
        </Grid>
    )
}