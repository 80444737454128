import React, { useState, useEffect } from "react";

import { BottomNavigation, BottomNavigationAction, useTheme, Paper, Fab } from '@mui/material';
// Icons
import WalletIcon from '@mui/icons-material/Wallet';
import TodayIcon from '@mui/icons-material/Today';
import CalendarMonth from '@mui/icons-material/CalendarMonth';
import AccountIcon from '@mui/icons-material/AccountCircle';
import AddIcon from '@mui/icons-material/Add';

export default function BottomNavigationBar(props) {
    // Theme
    const theme = useTheme();
    // Return
    return (
        <Paper 
            sx={{
                position: 'fixed', 
                bottom: 0, 
                left: 0, 
                right: 0, 
                backgroundColor: theme.palette.mode == 'dark' ? theme.palette.grey[800] : theme.palette.grey[100],
                backgroundImage: 'none',
                opacity: 1
            }} 
        >
            <BottomNavigation
                showLabels
                value={props.value}
                onChange={(event, newValue) => {
                    props.setValue(newValue);
                }}
                sx={{
                    mb: {xs: 1.5, sm: 1.5, md: 1, lg: 0},
                    bgcolor: theme.palette.mode == 'dark' ? theme.palette.grey[800] : theme.palette.grey[100],
                    opacity: 1
                }}

            >
                <BottomNavigationAction label="Hoy" icon={<TodayIcon /> } />
                <BottomNavigationAction sx={{mr: 1}} label="Calendario" icon={<CalendarMonth /> } />
                <Fab 
                    color="primary" 
                    aria-label="add" 
                    sx={{
                        position: 'relative', 
                        bottom: '10px',
                        minWidth: '56px'
                    }}
                    onClick={() => props.setRegisterToClass(true)}
                    >
                    <AddIcon />
                </Fab>
                <BottomNavigationAction sx={{ml: 1}} label="Membresías" icon={<WalletIcon />} />
                <BottomNavigationAction label="Perfil" icon={<AccountIcon />} />
            </BottomNavigation>
        </Paper>
    );
}