import React from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';

export default function Logo (props) {
    const theme = useTheme();
    return (
        <Box display="flex" alignItems="center">
            <Grid container direction='column'>
                <Grid item xs={8}>
                    <Typography
                        sx={{
                            fontFamily: 'LarkenRegular',
                            fontSize: '40px',
                            lineHeight: '1',
                            color: props.colorOverride ? props.colorOverride : theme.palette.text.primary
                        }}
                    >
                        SCULPT
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography
                        sx={{
                            fontFamily: 'InterRegular',
                            fontSize: '9px',
                            letterSpacing: '0.45em',
                            color: props.colorOverride ? props.colorOverride : theme.palette.text.primary
                        }}
                    >
                        WELLNESS STUDIO
                    </Typography>
                </Grid>
            </Grid>
            
            
        </Box>
    );
}