import React, { useEffect, useState } from 'react';
import { Grid, Grow, Box, Typography, useTheme, Link } from '@mui/material';
import axios from 'axios';
// components
import PackCard from '../PackCard/PackCard';
import ScheduleCalendar from '../ScheduleCalendar/ScheduleCalendar';
import Copyright from '../Copyright/Copyright';
import Carousel from './Carousel';
import AppTopBarLanding from '../Navigation/AppTopBar/AppTopBarLanding';
// Images
import Studio1 from '../../assets/images/studio_1.JPG';
import Studio2 from '../../assets/images/studio_2.JPG';
import Studio3 from '../../assets/images/studio_3.JPG';
import Studio4 from '../../assets/images/studio_4.JPG';
import Studio5 from '../../assets/images/studio_5.JPG';
import Studio6 from '../../assets/images/studio_6.JPG';
import Studio7 from '../../assets/images/studio_7.JPG';
import Studio8 from '../../assets/images/studio_8.JPG';
import Studio9 from '../../assets/images/studio_9.JPG';
import Studio10 from '../../assets/images/studio_10.JPG';
import Studio11 from '../../assets/images/studio_11.JPG';
import Classes1 from '../../assets/images/landing_card_1.JPG';
import Classes2 from '../../assets/images/landing_card_2.JPG';
import Classes3 from '../../assets/images/landing_card_3.JPG';

export default function Landing(props) {
    const [packs, setPacks] = useState([]);
    const [selectedPack, setSelectedPack] = useState(null);
    const [selectedClass, setSelectedClass] = useState(null);
    const theme = useTheme();
    const isDarkMode = theme.palette.mode.mode === 'dark';

    // images array
    const images = [Studio1, Studio2, Studio3, Studio4, Studio5, Studio6, Studio7, Studio8, Studio9, Studio10, Studio11];
    const classesImages = [Classes1, Classes2, Classes3];

    useEffect(() => {
        // Obtain available packs on load
        getPacks();
        // Wierd bug, scroll to the top when landing component loads
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 500);
    }, []);

    // Hear for changes in the selected class or pack
    useEffect(() => {
        if ((selectedClass || selectedPack) && props.logguedInUser) {
            props.setView('schedule');
        } else if ((selectedClass || selectedPack) && !props.logguedInUser) {
            props.setView('login');
        }
    }, [selectedClass, selectedPack]);

    const getPacks = async () => {
        // Function retrieves packs from DB and add it to state
        await axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/pack/getAll`)
            .then((res) => {
                setPacks(res.data.result);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        <> 
            <AppTopBarLanding {...props}/>
            {/* First landing page section */}
            <Grow in={(props.view == 'landing')}>
                <Grid container direction='column' sx={{height: '100vh'}}>
                    {/* Carousel */}
                    <Grid item xs alignContent={'center'} sx={{width: '100%'}} alignSelf={'center'}>
                        {images.length >=4 &&
                            <div>
                                <Carousel images={images}/>
                            </div>
                        }
                    </Grid>
                    {/* Movement is therapy text */}
                    <Grid item xs alignContent={'center'}>
                        <Typography
                            sx={{
                                fontFamily: 'LarkenRegular',
                                fontSize: {xs: '6vw', sm: '4vw', md: '3vw', lg: '2vw'},
                                lineHeight: '1',
                                display: 'inline-block',
                                mt: '20%'
                            }}
                        >MOVEMENT IS</Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Liana',
                                fontSize: {xs: '10vw', sm: '8vw', md: '5vw', lg: '4vw'},
                                lineHeight: '1',
                                display: 'inline-block'
                            }}
                        >Therapy</Typography>
                    </Grid>
                    {/* Arrows to indicate the user there's more down */}
                    <Grid item xs alignContent={'center'}>
                        <div>
                            <svg className="arrows">
                                <path className="a1" d="M0 0 L30 32 L60 0" style={{stroke: theme.palette.mode === 'dark' ? '#FFF' : '#000'}}></path>
                                <path className="a2" d="M0 20 L30 52 L60 20" style={{stroke: theme.palette.mode === 'dark' ? '#FFF' : '#000'}}></path>
                                <path className="a3" d="M0 40 L30 72 L60 40" style={{stroke: theme.palette.mode === 'dark' ? '#FFF' : '#000'}}></path>
                            </svg>
                        </div>
                    </Grid>
                </Grid>
            </Grow>
            {/* Second landing page section */}
            <Grow in={(props.view == 'landing')} id='second-landing-section'>
                <Box
                    sx={{
                        position: 'absolute',
                        zIndex: 1,
                        width: '100%',
                        height: '100%',
                        backgroundColor: (theme.palette.mode == 'dark') ? 'none' : theme.palette.background.paper,
                        opacity: 0.9,
                    }}
                >
                    {/* Nuestras clases */}
                    <Grid container direction={'column'} id='ourClasses'>
                        <Grid 
                            item 
                            xs={2} 
                            sx={{
                                backgroundColor: theme.palette.mode === 'dark' ? '#FFF' : '#000',
                                color: theme.palette.mode === 'dark' ? '#000' : '#FFF',
                            }}
                            m={2}
                        >
                            <Typography fontFamily={'LarkenBold'} fontSize={22}>NUESTRAS CLASES</Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    overflowX: 'auto',
                                    width: '100%',
                                    '&::-webkit-scrollbar': {
                                        display: 'none',
                                    },
                                    msOverflowStyle: 'none',
                                    scrollbarWidth: 'none',
                                }}
                            >
                                {classesImages.map((image, index) => (
                                    <Box
                                        key={index}
                                        component="img"
                                        src={image}
                                        alt={`Class ${index + 1}`}
                                        sx={{
                                            width: {xs: '80%', sm: '60%', md: '30%', lg: '20%'},
                                            flexShrink: 0,
                                            marginRight: '16px',
                                            marginLeft: index === 0 ? '20px' : '0',
                                        }}
                                    />
                                ))}
                            </Box>
                        </Grid>
                    </Grid>
                    {/* Class packs */}
                    <Grid container direction={'column'} id='memberships'>
                        <Grid 
                            item 
                            xs={2} 
                            sx={{
                                backgroundColor: theme.palette.mode === 'dark' ? '#FFF' : '#000',
                                color: theme.palette.mode === 'dark' ? '#000' : '#FFF'
                            }}
                            mt={2}
                            ml={2}
                            mr={2}
                        >
                            <Typography fontFamily={'LarkenBold'} fontSize={22}>MEMBRESÍAS</Typography>
                        </Grid>
                    </Grid>
                    <Grid 
                        container 
                        direction={'row'} 
                        spacing={2} 
                        sx={{
                            width: '90%', 
                            margin: '0 auto', 
                            justifyContent: 'center', 
                            alignItems: 'center'
                        }}
                    >
                        {packs && packs.map((pack, i) => (
                            <Grid 
                                xs 
                                item
                                minWidth={'300px'}
                                sx={{ marginRight: '25px' }}
                                key={'pack-card-' + i}
                            >
                                <PackCard pack={pack} isDarkMode={isDarkMode} setSelectedPack={setSelectedPack}/>
                            </Grid>
                        ))}
                    </Grid>
                    {/* Schedule */}
                    <Grid container direction={'column'} id='calendar'>
                        <Grid 
                            item 
                            xs={2} 
                            sx={{
                                backgroundColor: theme.palette.mode === 'dark' ? '#FFF' : '#000',
                                color: theme.palette.mode === 'dark' ? '#000' : '#FFF'
                            }}
                            mt={4}
                            ml={2}
                            mr={2}
                            mb={3}
                        >
                            <Typography fontFamily={'LarkenBold'} fontSize={22}>CALENDARIO</Typography>
                        </Grid>
                    </Grid>
                    <Grid 
                        container 
                        direction={'column'} 
                        spacing={2} 
                        sx={{
                            width: '90%', 
                            margin: '0 auto', 
                            justifyContent: 'center', 
                            alignItems: 'center'
                        }}
                    >
                        <ScheduleCalendar isDarkMode={isDarkMode} setSelectedClass={setSelectedClass} coaches={props.coaches} logguedInUser={props.logguedInUser}/>
                    </Grid>
                    {/* MAP */}
                    <Grid container direction={'column'} id='contact'>
                        <Grid 
                            item 
                            xs={2} 
                            sx={{
                                backgroundColor: theme.palette.mode === 'dark' ? '#FFF' : '#000',
                                color: theme.palette.mode === 'dark' ? '#000' : '#FFF'
                            }}
                            mt={4}
                            ml={2}
                            mr={2}
                            mb={3}
                        >
                            <Typography fontFamily={'LarkenBold'} fontSize={22}>UBICACIÓN</Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <iframe 
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3828.5986551426804!2d-107.4582231!3d24.824962400000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86bcdbee67dc0c1f%3A0x3efe0127aff48345!2sPunto%20Cantabria!5e1!3m2!1ses-419!2smx!4v1727462015084!5m2!1ses-419!2smx" 
                                width={'100%'}
                                height="450" 
                                style={{border: 0}}
                                allowfullscreen="" 
                                loading="lazy" 
                                referrerpolicy="no-referrer-when-downgrade"
                            ></iframe>
                        </Grid>
                    </Grid>
                    {/* DATOS DE CONTACTO */}
                    <Grid container direction={'column'} id='contact'>
                        <Grid 
                            item 
                            xs={2} 
                            sx={{
                                backgroundColor: theme.palette.mode === 'dark' ? '#FFF' : '#000',
                                color: theme.palette.mode === 'dark' ? '#000' : '#FFF'
                            }}
                            mt={4}
                            ml={2}
                            mr={2}
                            mb={3}
                        >
                            <Typography fontFamily={'LarkenBold'} fontSize={22}>DATOS DE CONTACTO</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="body2" color={"text.secondary"} align="center" sx={{pb: 4}}>
                                <Link href={"tel:667-699-9667"}>
                                    Contáctanos al 667 699 9667
                                </Link>
                            </Typography>
                            <Typography variant="body2" color={"text.secondary"} align="center" sx={{pb: 4}}>
                                <Link href={"https://ig.me/m/sculptstudiomx"}>
                                    IG: @sculptstudiomx 
                                </Link>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Copyright/>
                </Box>
            </Grow>
        </>
    )
}