import React, { useState, useEffect } from "react";
import { Grid, Typography, useTheme, Button, Box, Avatar, Divider } from "@mui/material";
import { calculateDuration, getFormattedTime, getStatus } from "../../helpers/functions";
import CIcon from "@coreui/icons-react";
import * as icon from '@coreui/icons';

export default function ScheduleCard (props) {
    const [coachName, setCoachName] = useState({name: '', lastName: ''});
    const [coachPicture, setCoachPicture] = useState('');
    const [isRegistered, setIsRegistered] = useState(false);

    useEffect(() => {
        (props.logguedInUser) && checkIfisRegistered();
    }, [props.logguedInUser]);

    useEffect(() => {
        getCoachName(props.classData.teacherID);
    }, []);

    useEffect(() => {
        getCoachName(props.classData.teacherID);
    }, [props.coaches]);

    // Import theme
    const theme = useTheme();

    // Check if user is already registered in class
    const checkIfisRegistered = () => {
        let isRegistered = props.classData.participantIDS.includes(props.logguedInUser._id);
        setIsRegistered(isRegistered);
    }

    // Obtain coach name
    const getCoachName = (coachID) => {
        let coaches =  props.coaches;
        if (coaches.length != undefined && coaches.length > 0) {
            let coach = coaches.find(coach => coach._id == coachID);
            setCoachPicture(coach.pictureURL);
            setCoachName({name: coach.firstName, lastName: coach.lastName});
        } else {
            setCoachName({name: 'Coach', lastName: ''});
            setCoachPicture('');
        }
    }

    // Get available spaces
    const getAvailability = () => {
        let availableSpaces = props.classData.classCapacity - props.classData.participantIDS.length;
        return availableSpaces; 
    }

    // User can register?
    const canRegister = () => {
        let canRegister = (getAvailability() > 0) && !isRegistered && (props.classData.status == 'scheduled');
        return canRegister;
    }

    // Get class title
    const getClassTitleOrDesc = (string) => {
        switch (string) {
            case 'pilates_mat': return 'PILATES MAT'
            case 'barre': return 'BARRE'
            case 'sculpt': return 'SCULPT'
            case 'upper_body': return 'UPPER BODY'
            case 'lower_body': return 'LOWER BODY'
            case 'full_body': return 'FULL BODY'
            default: return string;
        }
    }

    // Return the component
    return (
        <Grid 
            container 
            direction='column' 
            sx={{
                mt: '10px',
                marginLeft: '5%',
                display: 'inline-block',
                width: '90%',
                // height: '170px',
                transition: 'all 0.35s',
                overflow: 'hidden',
                zIndex: 1,
                boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.5)',
                opacity: (canRegister()) ? 1 : 0.5,
            }}
            onClick={() => (canRegister()) && props.setSelectedClass(props.classData)}
        >
            {/* Class title */}
            <Grid 
                item 
                xs={2} 
                sx={{
                    p:0.5, 
                    width: '100%',
                    bgcolor: theme.palette.primary.main
                }}
            >
                <Grid container direction={'row'}>
                    {/* Class title */}
                    <Grid item xs={12} textAlign={'center'}>
                        <Typography sx={{color: theme.palette.grey[100], ml: '5px', fontFamily: 'LarkenBold', textTransform: 'uppercase', fontSize: '22px'}}>
                            {getClassTitleOrDesc(props.classData.classTitle)}
                        </Typography>
                    </Grid>
                </Grid>
                
            </Grid>
            <Grid item xs={4} textAlign={'center'}>
                {/* Profile picture of coach */}
                <Box m={1}>
                    {coachPicture ? 
                        <img src={coachPicture} alt='coach' style={{width: 80, height: 80, objectFit: 'cover', borderRadius: '50%'}}/> : 
                        <Avatar 
                        sx={{ 
                            bgcolor: '#000',
                            opacity: 0.40, 
                            display: 'inline-flex', 
                            height: 70, 
                            width: 70,
                            fontSize: 45,
                            fontFamily: 'LarkenBold',
                            margin: 1,
                        }}>
                            {String(coachName.name.charAt(0)).toLocaleUpperCase() + String(coachName.lastName.charAt(0)).toLocaleUpperCase()}
                        </Avatar>
                    }
                </Box>
                <Typography 
                    sx={{
                        fontSize: 20, 
                        fontFamily: 'InterRegular', 
                        color: theme.palette.primary.main, 
                        lineBreak: ''
                    }}
                >
                    {`${coachName.name} ${coachName.lastName}`}
                </Typography>
                <Divider orientation="horizontal" sx={{width: '90%', m:1}}/>
                {/* Class description */}
                <Typography sx={{fontSize: 14, m:1}}>
                    {getClassTitleOrDesc(props.classData.classDescription)}
                </Typography>
            </Grid>
            {/* Time display */}
            <Grid item xs={4} sx={{m:1}}>
                <Grid container direction='row'>
                    <Grid item xs textAlign={'initial'}>
                        <Typography sx={{fontSize: 20}}>
                            {getFormattedTime(props.classData.time)}
                        </Typography>
                        <Typography sx={{fontSize: 15}}>
                            {'Inicio'}
                        </Typography>
                    </Grid>
                    <Grid item xs textAlign={'center'} alignContent={'center'}>
                        <Typography 
                            sx={{
                                fontSize: 20, 
                                borderRadius: '50px',
                                padding: 0.5,
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.grey[100]
                            }}>
                            {calculateDuration(props.classData.time, props.classData.endTime)}
                        </Typography>
                    </Grid>
                    <Grid item xs textAlign={'end'}>
                        <Typography sx={{fontSize: 20}}>
                            {getFormattedTime(props.classData.endTime)}
                        </Typography>
                        <Typography sx={{fontSize: 15}}>
                            {'Fin'}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            {/* Remaining spaces */}
            <Grid item xs={2}>
                <Typography 
                    sx={{
                        fontSize: 16, 
                        textAlign: 'center', 
                        p: 1,
                        color: (getAvailability() >= 5) ? theme.palette.success.main :
                                (getAvailability() >= 3) ? theme.palette.warning.main : theme.palette.error.main
                    }}
                >
                    {`${getAvailability()} espacios disponibles`}
                </Typography>
            </Grid>
        </Grid>
    )
}