import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// MUI Components
import { 
    Modal, 
    Autocomplete, 
    Backdrop, 
    Fade, 
    Box, 
    Typography, 
    TextField, 
    Divider, 
    Select, 
    MenuItem, 
    Grid, 
    InputLabel, 
    FormControl, 
    Button, 
    IconButton, 
    FormControlLabel, 
    Switch,
    FormLabel,
    RadioGroup,
    Radio
} from '@mui/material';

// Custom Components
import DialogSports from '../Dialog/Dialog';
import { Close } from '@mui/icons-material';

import axios from 'axios';

// Render field function
import renderField from '../../helpers/RenderField';

export default function ActionsModal(props) {
    const [errors, setErrors] = useState({});
    const [dialogProps, setDialogProps] = useState({ title: '', content: '', leftButton: '', rightButton: '', open: false });
    const [formData, setFormData] = useState({});
    const [options, setOptions] = useState([]);

    // useEffect to handle changes in modalOpen, selectedItem, action and adminView
    useEffect(() => {        
        if (props.action === 'Edit' && props.selectedItem) {
            setFormData(props.selectedItem);
        } else {
            setFormData({});
        }

        if (props.action === 'Add' && (props.adminView === 'sale' || props.adminView === 'schedule')) {
            setCurrentDateTime();
        }
    }, [props.modalOpen, props.selectedItem, props.action, props.adminView]);

    // handle close modal
    const handleClose = () => props.setModalOpen(false);

    // Convert Central Standard Time to UTC for saving in database
    const convertToUTCString = (date) => {
        const utcDate = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
        date.getUTCDate(), date.getUTCHours(),
        date.getUTCMinutes(), date.getUTCSeconds());
        return new Date(utcDate).toISOString();
    }

    // handle change form data values
    const handleChange = (key, value) => {
        // if key is date, remove time to avoid conflicts
        (key === 'date') && value.setHours(0, 0, 0, 0);
        setFormData({ ...formData, [key]: value });
    };

    // set current date and time
    const setCurrentDateTime = () => {
        const currentDate = new Date();

        setFormData(prevFormData => ({
        ...prevFormData,
        date: currentDate,
        time: currentDate
        }));
    }

    // handle dialog left button
    const handleDialogLeftButton = () => {
        setDialogProps(
            { 
                title: '', 
                content: '', 
                leftButton: '', 
                rightButton: '', 
                open: false
            }
        );
        handleClose();
    }

    // handle dialog right button
    const handleDialogRightButton = () => {
        setDialogProps(
            { 
                title: '', 
                content: '', 
                leftButton: '', 
                rightButton: '', 
                open: false
            }
        );
    }

    // handle submit form
    const handleSubmit = async () => {
        if (!isValid()) {
            setDialogProps(
                { 
                    title: 'Alerta ⚠️', 
                    content: 'Por favor verifica que todos los campos tengan información y vuelve a intentarlo', 
                    rightButton: 'Ok', 
                    open: true
                }
            );
            return;
        }

        const formattedData = { ...formData };

        // Convert classCapacity to Number format
        if (formattedData.classCapacity) {
            formattedData.classCapacity = parseInt(formattedData.classCapacity);
        }

        // Check for each key in formattedData if it is a date and convert the value of that key into mexico city time string
        Object.keys(formattedData).forEach(key => {
            if (key === 'time' || key === 'purchaseTime' || key === 'endTime' || key === 'date' || key === 'purchaseDate' || key === 'endDate') {
                formattedData[key] = convertToUTCString(new Date(formattedData[key]));
            } else if (key === 'classTitle' || key === 'classDescription') {
                if (formattedData[key] === 'other') {
                    (key === 'classTitle') && (formattedData[key] = formattedData['classTitle-tf']);
                    (key === 'classDescription') && (formattedData[key] = formattedData['classDescription-tf']);
                }
            }
        });

        // Request to add or edit item
        await axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/${props.adminView}/${props.action === 'Add' ? 'create' : 'update'}`, formattedData)
            .then((res) => {
                if (res.status == 200 && res.data.success == true) {
                    setDialogProps(
                        { 
                            title: 'Información ❗️', 
                            content: `${props.getDisplayName()} ${(props.action == 'Add' ? 'Agregado' : 'Editado')} correctamente`, 
                            leftButton: 'Ok', 
                            rightButton: '', 
                            open: true
                        }
                    );
                } else {
                    setDialogProps(
                        { 
                            title: 'Alerta ⚠️', 
                            content: `${props.getDisplayName()} No se ha podido ${(props.action == 'Add' ? 'Agregar' : 'Editar')} correctamente, por favor contacta a soporte.`, 
                            leftButton: 'Ok', 
                            rightButton: '', 
                            open: true
                        }
                    );
                }
            })
            .catch((err) => {
                setDialogProps(
                    { 
                        title: 'Alerta ⚠️', 
                        content: `${props.getDisplayName()} No se ha podido ${(props.action == 'Add' ? 'Agregar' : 'Editar')} correctamente, por favor contacta a soporte.`, 
                        leftButton: 'Ok', 
                        rightButton: '', 
                        open: true
                    }
                );
            });
    }

    // Excluded keys
    const excludedKeys = [
        "id",
        "createdAt",
        "_id",
        "cart",
        "reservations",
        "assignedClasses",
        "participantIDS",
        "creditsPilates",
        "creditsSculpt",
        "phoneVerified",
        "discipline",
        "packs",
        "otp"
    ];

    // Check if form data is valid
    const isValid = () => {
        let hasErrors = false;
        let errors = {};
        Object.entries(props.schema).forEach(field => {
            const fieldKey = field[0];
            const fieldValue = field[1];
            (fieldValue.subtype === 'number' && (formData[fieldKey] === '' || formData[fieldKey] === undefined)) && (formData[fieldKey] = 0);
            (fieldValue.subtype === 'boolean' && (formData[fieldKey] === '' || formData[fieldKey] === undefined)) && (formData[fieldKey] = false);
            if ((fieldValue.required && !String(formData[fieldKey])) || (fieldValue.required && formData[fieldKey] === undefined)) {
                hasErrors = true;
                errors[fieldKey] = 'Este campo es requerido';
            }
        });
        setErrors(errors);
        return !hasErrors;
    }

    // Handle autocomplete change
    const handleAutocompleteChange = (fieldKey, value) => {
        axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/user/findbyName`, { query: value })
        .then(response => {
            setOptions(response.data.result);
        })
        .catch(error => console.error(error));
    
        setFormData(prevState => ({
            ...prevState,
            [fieldKey]: value
        }));
    };

    // Handle change for autocomplete (option selection)
    const handleAutocompleteOptionChange = (fieldKey, value) => {
        setFormData(prevState => ({
            ...prevState,
            [fieldKey]: (fieldKey === 'purchaserID') ? value._id : value
        }));
    };

    // Is of type date

    // Get action
    const getAction = () => {
        switch (props.action) {
            case "Add" : return "Agregar";
            case "Edit" : return "Editar" ;
        }
    }

    return (
        <Modal
            open={props.modalOpen}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
        >
            <Fade in={props.modalOpen}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    width: {xs: '100vw', sm: '90vw', md: '80vw'},
                    height: {xs: '100vh', sm: '90vh', md: '80vh'},
                    overflowY: 'auto'
                }}>
                <DialogSports dialogProps={dialogProps} setDialogProps={setDialogProps} handleDialogLeftButton={handleDialogLeftButton} handleDialogRightButton={handleDialogRightButton}/>
                <Typography variant="h6" component="h2" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {`${getAction()} ${props.getDisplayName()}`}
                    <IconButton onClick={handleClose}>
                        <Close />
                    </IconButton>
                </Typography>
                    <Divider sx={{ my: 2 }} />
                    <Grid container spacing={2}>
                        {(props.schema && Object.entries(props.schema).length > 0)  && 
                            (Object.entries(props.schema)) && Object.entries(props.schema).map(field => renderField(field, formData, handleChange, errors, handleAutocompleteChange, options, excludedKeys, props.coaches, props.adminView, handleAutocompleteOptionChange)
                        )}
                    </Grid>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                        <Button variant="contained" onClick={handleSubmit} sx={{color: '#FFF', textTransform: 'none'}}>
                            {getAction()}
                        </Button>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
}


ActionsModal.propTypes = {
    modalOpen: PropTypes.bool.isRequired,
    setModalOpen: PropTypes.func.isRequired,
    getDisplayName: PropTypes.func.isRequired,
    adminView: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
    selectedItem: PropTypes.object
};
