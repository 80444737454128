import React, { useState, useEffect } from "react";
import { 
    AppBar, 
    Toolbar, 
    Box, 
    Typography, 
    Button, 
    Drawer,
    List,
    Divider,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    useTheme,
    Popover,
    MenuItem,
    Grid
} from "@mui/material";
import { 
    Menu,
    Logout,
} from "@mui/icons-material";
import Logo from "../../../assets/Logo";
import { colors } from '../../../themes/themeCons';
import consts from "../../../consts/consts";
import CIcon from "@coreui/icons-react";
import * as icon from '@coreui/icons';

export default function AppTopBar(props) {
    // States
    const [anchorEl, setAnchorEl] = useState(null);
    const theme = useTheme();
    const [state, setState] = useState({ left: false, });
    const [scrollPosition, setScrollPosition] = useState(0);
    const [showTopBar, setShowTopBar] = useState(true);

    // Detect scroll direction
    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPosition = window.pageYOffset;
            if (currentScrollPosition > 50) {
                if (currentScrollPosition > scrollPosition) {
                    setShowTopBar(false); // Scrolling down
                } else {
                    setShowTopBar(true); // Scrolling up
                }
            }
            setScrollPosition(currentScrollPosition);
        };
    
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [scrollPosition]);

    // Show hide top bar
    const topBarStyle = {
        transition: 'transform 0.3s ease-in-out',
        transform: showTopBar ? 'translateY(0)' : 'translateY(-100%)'
    };

    // Toggle drawer
    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
    
        setState({ ...state, [anchor]: open });
    };

    // Handle click on a category button
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    // Handle close of the popover
    const handleClose = () => {
        setAnchorEl(null);
    };

    // Handle login button
    let handleLoginButton = () => {
        if (props.logguedInUser !== null) {
            localStorage.removeItem('ssmx-LoggedInUser');
            props.setLogguedInUser(null);
            props.setView('landing');
        } else {
            props.setView('login');
        }
    }

    // Clear cache
    const clearCache = () => {
        localStorage.clear();
        window.location.reload();
    }
    
    // Drawer list of categories
    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
        <List>
            <ListItem key={'landing'} disablePadding>
                <ListItemButton onClick={() =>  props.setView('landing')}>
                    <ListItemIcon>
                        <CIcon icon={icon.cilHome} style={{width: '25px'}}/>
                    </ListItemIcon>
                    <ListItemText primary={'Landing page'} />
                </ListItemButton>
            </ListItem>
            <ListItem key={'dashboard'} disablePadding>
                <ListItemButton onClick={() =>  props.setView('dashboard')}>
                    <ListItemIcon>
                        <CIcon icon={icon.cilGrid} style={{width: '25px'}}/>
                    </ListItemIcon>
                    <ListItemText primary={'Dashboard'} />
                </ListItemButton>
            </ListItem>
            {consts.adminCategories.map((category, index) => (
                <ListItem key={category.id} disablePadding>
                    <ListItemButton onClick={() =>  props.setView(category.id)}>
                        <ListItemIcon>
                            <CIcon icon={category.icon} style={{width: '25px'}}/>
                        </ListItemIcon>
                        <ListItemText primary={category.displayName} />
                    </ListItemButton>
                </ListItem>
            ))} 
        </List>
        <Divider />
            <ListItem key={'closeSession'} disablePadding>
                <ListItemButton onClick={() => handleLoginButton()}>
                    <ListItemIcon>
                        <Logout sx={{ color: theme.palette.primary.main }}/>
                    </ListItemIcon>
                    <ListItemText primary={props.logguedInUser ? 'Cerrar sesión' : 'Iniciar sesión'} sx={{ color: theme.palette.primary.main }}/>
                </ListItemButton>
            </ListItem>
        </Box>
    );

    // Return
    return (
        <AppBar 
            position={"sticky"}
            sx={{
                ...topBarStyle,
                bgcolor: (props.admin) ? theme.palette.primary.main : theme.palette.mode == 'dark' ? theme.palette.grey[800] : theme.palette.grey[100],
                backgroundImage: 'none',
                boxShadow: 'none',
            }}
        >
            <Toolbar>
                {
                    props.admin &&
                    <Button onClick={toggleDrawer('left', true)} sx={{flexGrow: 1}}>
                        <Menu sx={{ color: '#FFF'}}/>
                    </Button>
                }
                <Box
                    sx={{
                        height: 60,
                        width: 200,
                        display: 'flex',
                        alignItems: props.admin ? 'center' : 'left',
                        justifyContent: props.admin ? 'center' : 'left',
                        flexGrow: 20
                    }}
                >
                    <Logo colorOverride={props.admin ? '#FFF' : theme.palette.text.primary}/>
                </Box>
                {props.logguedInUser
                ?
                // Admin loggued in, use fab icon with user icon
                    <>
                        <Button
                            color={(props.admin || props.view === 'landing') ? 'secondary' : (theme.palette.mode === 'dark') ? 'secondary' : 'primary'} 
                            variant='text'
                            onClick={handleClick} 
                            sx={{
                                textTransform: 'none', 
                                flexGrow: 1, 
                                maxHeight: '40px', 
                                textWrap: 'nowrap',
                                borderRadius: '20px',
                                padding: 0,
                                justifyContent: 'right',
                                right: '-10px'
                            }}
                            >
                                {props.logguedInUser.pictureURL ?
                                    <img src={props.logguedInUser.pictureURL} style={{width: '45px', height: '45px', objectFit: 'cover', borderRadius: '25px'}}/>    
                                :
                                    <CIcon icon={icon.cilUser} style={{width: '25px', marginRight: '10px'}}/>
                                }
                        </Button>
                        <Popover
                            open={Boolean(anchorEl)}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <MenuItem onClick={() => {handleClose(); props.setView('myProfile');}}>Mi Perfil</MenuItem>
                            {props.logguedInUser.type == 'admin' && <MenuItem onClick={() => {handleClose(); props.setView('dashboard');}}>Dashboard</MenuItem>} 
                            <MenuItem onClick={() => {handleClose(); clearCache();}}>Cerrar sesión</MenuItem>
                        </Popover>
                    </>
                :
                // Not loggued in, use login button
                    <Button 
                        color='primary' 
                        variant={'text'}
                        onClick={() => handleLoginButton()}
                        sx={{
                            textTransform: 'none', 
                            flexGrow: 1, 
                            maxHeight: '35px', 
                            textWrap: 'nowrap'
                        }}
                        >
                        {'Iniciar sesión'}
                    </Button>
                }
            </Toolbar>
            <Drawer
                anchor={'left'}
                open={state['left']}
                onClose={toggleDrawer('left', false)}
            >
                {list('left')}
            </Drawer>
        </AppBar>
    )
}